import { ApiInstructionsInterface } from './api-instructions.interface';

export const API_INSTRUCTIONS: ApiInstructionsInterface[] = [
    {
        headlineType: 'headline',
        headline: 'Überblick',
        text: 'In diesem Abschnitt erhalten Sie einen grundlegenden Überblick über unsere API. Hier finden Sie Informationen zur Funktionalität und den verfügbaren Endpunkten. Wenn Sie gerade erst anfangen, ist dies der richtige Ort, um sich mit unserer API vertraut zu machen.',
    },
    {
        headlineType: 'headline',
        headline: 'Erste Schritte',
        text: 'Bevor Sie die API nutzen können, müssen Sie einen API-Schlüssel erhalten. Diesen erhalten Sie über die Schaltfläche "Übersicht" in Ihrem Entwicklerkonto. Nachdem Sie Ihren API-Schlüssel erhalten haben, können Sie die API in Ihrer Entwicklungsumgebung einrichten und erste Anfragen stellen. In diesem Abschnitt erfahren Sie, wie Sie die ersten Schritte zur Nutzung unserer API unternehmen können.',
    },
    {
        headlineType: 'headline',
        headline: 'API-Schlüssel',
        text: 'Der API-Schlüssel ist der Zugangsschlüssel zu unserer API. Sie benötigen ihn, um Anfragen an unsere Server zu senden und auf die Ressourcen zuzugreifen. Um Ihren API-Schlüssel zu erhalten, klicken Sie einfach auf die Schaltfläche "Übersicht" in Ihrem Entwicklerkonto. Bewahren Sie Ihren Schlüssel sicher auf und teilen Sie ihn nicht mit anderen. Die Sicherheit Ihres Schlüssels ist entscheidend, um unbefugte Zugriffe auf Ihr Konto zu verhindern.'
    },
    {
        headlineType: 'headline',
        headline: 'Dokumentation',
        text: 'Unsere API-Dokumentation ist in mehrere Kapitel unterteilt, um Ihnen die Nutzung unserer API zu erleichtern. Hier sind die Hauptkapitel, die Sie in der Dokumentation finden werden:'
    },
    {
        headlineType: 'subheadline',
        headline: 'Routes und Request-Methoden',
        text: 'Dieser Abschnitt bietet eine detaillierte Übersicht über die verfügbaren API-Routen und die unterstützten HTTP-Request-Methoden (z. B. GET, POST, PUT, DELETE). Sie erfahren, welche Aktionen Sie mit unserer API durchführen können.'
    },
    {
        headlineType: 'subheadline',
        headline: 'Parameter',
        text: 'Hier finden Sie Informationen zu den verschiedenen Parametern, die Sie in Ihren API-Anfragen verwenden können. Wir erklären, welche Parameter erforderlich oder optional sind und wie Sie sie korrekt übergeben.'
    },
    {
        headlineType: 'subheadline',
        headline: 'Body',
        text: 'Im Abschnitt "Body" erfahren Sie, wie Sie Daten im Body Ihrer API-Anfragen senden. Dies ist besonders relevant, wenn Sie Daten erstellen oder aktualisieren möchten.'
    },
    {
        headlineType: 'subheadline',
        headline: 'Response',
        text: 'Hier finden Sie Beispiele für die von unserer API zurückgegebenen Antworten. Wir erläutern die Struktur der Antwort und die verschiedenen Elemente, die Sie erwarten können.'
    },
];
