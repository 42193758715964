import { RoleType } from '../role/role.enum';
import { RootResourceService } from '../root/root-resource-service';
import { EditUserInterface, UserInterface } from './user.interface';
import axios from 'axios';

export class UserService extends RootResourceService<UserInterface> {
    constructor() {
        super('user');
    }

    async update(token: string, data: UserInterface): Promise<any> {
        try {
            const roleType = Object.keys(RoleType)[data.role.id - 1]
            const editUser: EditUserInterface = { ...data, role: roleType };

            const response = await axios.put<string>(`${this.resource_url}/${data.id}`, editUser, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            this.logger.log(`${this.resource_url} updated successfully.`);

            return response.data;
        } catch (error) {
            this.logger.error(error);

            throw error;
        }
    }

    async changePassword(token: string, data: { password: string }): Promise<any> {
        try {
            const response = await axios.put<string>(`${this.resource_url}/password`, data, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            this.logger.log(`${this.resource_url} updated successfully.`);

            return response.data;
        } catch (error) {
            this.logger.error(error);

            throw error;
        }
    }
}
