import { UserService } from '../../../../service/user/user.service';
import DataContainer from '../../../container/DataContainer/DataContainer';
import { EditUserInterface } from '../../../../service/user/user.interface';

function Users() {
  const editUserObject: EditUserInterface = {
    username: "",
    role: ""
  };

  return (
    <DataContainer
      resourceService={new UserService()}
      editResourceObject={editUserObject}
      title="Users"
      deleteAllowed={true}
      updateAllowed={true}
    />
  )
}

export default Users;