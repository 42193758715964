import { EditProblempointInterface } from '../../../../service/problempoint/problempoint.interface';
import { ProblempointService } from '../../../../service/problempoint/problempoint.service';
import DataContainer from '../../../container/DataContainer/DataContainer';

function Organisations() {
    const editProblempointObject: EditProblempointInterface = {
        name: ""
    };

    return (
        <DataContainer
            resourceService={new ProblempointService()}
            editResourceObject={editProblempointObject}
            title="Problempoints"
            deleteAllowed={true}
            updateAllowed={true}
        />
    )
}

export default Organisations;