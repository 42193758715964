import React from 'react';
import { Typography, Box, Dialog, AppBar, Toolbar, IconButton, Slide, Avatar } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import ABPALogo from '../../../assets/logos/ABPA_logo.jpg';
import { TransitionProps } from '@mui/material/transitions';
import './FullScreenDialog.css';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface FullScreenDialogProps {
    openDialog: boolean,
    closeDialogHandler: () => void,
    title: string,
    children: React.ReactNode,
}

function FullScreenDialog(props: FullScreenDialogProps) {
    const { openDialog, closeDialogHandler, title } = props;

    return (
        <Dialog open={openDialog} className="fullscreen-dialog" classes={{ paper: "fullscreen-dialog-card" }} TransitionComponent={Transition} fullScreen>
            <AppBar className="fullscreen-dialog-appbar" position="relative" elevation={1}>
                <Toolbar className="dialog-toolbar">
                    <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
                        <IconButton
                            className="dialog-button"
                            aria-label="close"
                            edge="start"
                            onClick={closeDialogHandler}
                        >
                            <ArrowBack className="dialog-icon" />
                        </IconButton>
                        <Typography variant="h4" component="div" className="dialog-title">
                            {title}
                        </Typography>
                        <Avatar className="avatar">
                            <img src={ABPALogo} alt="ABPA-Logo" />
                        </Avatar>
                    </Box>
                </Toolbar>
            </AppBar>
            <div className="dialog-container">
                {props.children}
            </div>
        </Dialog>
    );
}

export default FullScreenDialog;
