import { ApiDocumentationInterface } from './api-documentation.interface';

export const API_DOCUMENTATION: ApiDocumentationInterface[] = [
    {
        subheader: 'User',
        items: [
            {
                path: '/api/user',
                method: 'GET',
                description: 'Get user information',
                params: [],
                header: [
                    {
                        name: 'Authorization',
                        type: 'Bearer <Authorization Value>',
                    },
                ],
                body: [],
                response: [
                    {
                        name: 'id',
                        type: 'number',
                    },
                    {
                        name: 'name',
                        type: 'string',
                    },
                    {
                        name: 'email',
                        type: 'string',
                    },
                    {
                        name: 'role',
                        type: 'string',
                    },
                ],
            },
            {
                path: '/api/user/:id',
                method: 'PUT',
                description: 'Update user information',
                params: [
                    {
                        name: 'id',
                        type: 'number',
                    },
                ],
                header: [
                    {
                        name: 'Authorization',
                        type: 'Bearer <Authorization Value>',
                    },
                ],
                body: [
                    {
                        name: 'name',
                        type: 'string',
                    },
                    {
                        name: 'email',
                        type: 'string',
                    },
                    {
                        name: 'password',
                        type: 'string',
                    },
                ],
                response: [
                    {
                        name: 'id',
                        type: 'string',
                    },
                    {
                        name: 'name',
                        type: 'string',
                    },
                    {
                        name: 'email',
                        type: 'string',
                    },
                    {
                        name: 'role',
                        type: 'string',
                    },
                ],
            },
            {
                path: '/api/user/:id',
                method: 'DELETE',
                description: 'Delete user',
                params: [
                    {
                        name: 'id',
                        type: 'number',
                    },
                ],
                header: [
                    {
                        name: 'Authorization',
                        type: 'Bearer <Authorization Value>',
                    },
                ],
                body: [],
                response: [],
            },
        ],
    },
    {
        subheader: 'Authentication',
        items: [
            {
                path: '/api/authentication',
                method: 'POST',
                description: 'User Login with username and password',
                params: [],
                header: [],
                body: [
                    {
                        name: 'username',
                        type: 'string',
                    },
                    {
                        name: 'password',
                        type: 'string',
                    },
                ],
                response: [
                    {
                        name: 'access_token',
                        type: 'string',
                    },
                ],
            },
            {
                path: '/api/authentication/register',
                method: 'POST',
                description: 'Register; https://abpa-cloud.de/api/authentication/register',
                params: [],
                header: [
                    {
                        name: 'Authorization',
                        type: 'Bearer <Authorization Value>',
                    },
                ],
                body: [
                    {
                        name: 'name',
                        type: 'string',
                    },
                    {
                        name: 'email',
                        type: 'string',
                    },
                    {
                        name: 'password',
                        type: 'string',
                    },
                ],
                response: [
                    {
                        name: 'token',
                        type: 'string',
                    },
                ],
            },
        ],
    },
    {
        subheader: 'Mobile User',
        items: [
            {
                path: '/api/mobileuser',
                method: 'POST',
                description: 'Create a user; https://abpa-cloud.de/api/mobileuser',
                params: [],
                header: [
                    {
                        name: 'Authorization',
                        type: 'Bearer <Authorization Value>',
                    },
                ],
                body: [
                    {
                        name: 'name',
                        type: 'string',
                    },
                    {
                        name: 'email',
                        type: 'string',
                    },
                ],
                response: [],
            },
            {
                path: '/api/mobileuser',
                method: 'GET',
                description: 'Get all mobile users; https://abpa-cloud.de/api/mobileuser',
                params: [],
                header: [
                    {
                        name: 'Authorization',
                        type: 'Bearer <Authorization Value>',
                    },
                ],
                body: [],
                response: [
                    {
                        name: 'id',
                        type: 'number',
                    },
                    {
                        name: 'name',
                        type: 'string',
                    },
                    {
                        name: 'email',
                        type: 'string',
                    },
                ],
            },
            {
                path: '/api/mobileuser/:id',
                method: 'PUT',
                description: 'Update mobile user by ID; https://abpa-cloud.de/api/mobileuser',
                params: [
                    {
                        name: 'id',
                        type: 'number',
                    },
                ],
                header: [
                    {
                        name: 'Authorization',
                        type: 'Bearer <Authorization Value>',
                    },
                ],
                body: [
                    {
                        name: 'name',
                        type: 'string',
                    },
                    {
                        name: 'email',
                        type: 'string',
                    },
                    {
                        name: 'password',
                        type: 'string',
                    },],
                response: [
                    {
                        name: 'id',
                        type: 'string',
                    },
                    {
                        name: 'name',
                        type: 'string',
                    },
                    {
                        name: 'email',
                        type: 'string',
                    },
                ],
            },
            {
                path: '/api/mobileuser/:id',
                method: 'DELETE',
                description: 'Delete mobile user by ID; https://abpa-cloud.de/api/mobileuser/:id',
                params: [
                    {
                        name: 'id',
                        type: 'number'
                    },
                ],
                header: [
                    {
                        name: 'Authorization',
                        type: 'Bearer <Authorization Value>',
                    },
                ],
                body: [],
                response: [],
            },
        ],
    },
    {
        subheader: 'Mobile Configuration',
        items: [
            {
                path: '/api/mobile-configuration',
                method: 'GET',
                description: 'Get all mobile configurations; https://abpa-cloud.de/api/mobile-configuration',
                params: [],
                body: [],
                header: [
                    {
                        name: 'Authorization',
                        type: 'Bearer <Authorization Value>',
                    },
                ],
                response: [
                    {
                        name: 'id',
                        type: 'string',
                    },
                    {
                        name: 'name',
                        type: 'string',
                    },
                    {
                        name: 'value',
                        type: 'string',
                    },
                ],
            },
            {
                path: '/api/mobile-configuration/:id',
                method: 'GET',
                description: 'Get mobile configuration by ID; https://abpa-cloud.de/api/mobile-configuration/:id',
                params: [
                    {
                        name: 'id',
                        type: 'number',
                    },
                ],
                header: [
                    {
                        name: 'Authorization',
                        type: 'Bearer <Authorization Value>',
                    },
                ],
                body: [],
                response: [
                    {
                        name: 'id',
                        type: 'number',
                    },
                    {
                        name: 'name',
                        type: 'string',
                    },
                    {
                        name: 'value',
                        type: 'string',
                    },
                ],
            },
            {
                path: '/api/mobile-configuration',
                method: 'POST',
                description: 'Create a mobile configuration; https://abpa-cloud.de/api/mobile-configuration',
                params: [],
                header: [
                    {
                        name: 'Authorization',
                        type: 'Bearer <Authorization Value>',
                    },
                ],
                body: [
                    {
                        name: 'name',
                        type: 'string',
                    },
                    {
                        name: 'value',
                        type: 'string',
                    },
                ],
                response: [
                    {
                        name: 'id',
                        type: 'number',
                    },
                    {
                        name: 'name',
                        type: 'string',
                    },
                    {
                        name: 'value',
                        type: 'string',
                    },
                ],
            },
            {
                path: '/api/mobile-configuration/:id',
                method: 'PUT',
                description: 'Update mobile configuration by ID; https://abpa-cloud.de/api/mobile-configuration/:id',
                params: [
                    {
                        name: 'id',
                        type: 'string',
                    },
                ],
                header: [
                    {
                        name: 'Authorization',
                        type: 'Bearer <Authorization Value>',
                    },
                ],
                body: [
                    {
                        name: 'name',
                        type: 'string',
                    },
                    {
                        name: 'value',
                        type: 'string',
                    },
                ],
                response: [
                    {
                        name: 'id',
                        type: 'string',
                    },
                    {
                        name: 'name',
                        type: 'string',
                    },
                    {
                        name: 'value',
                        type: 'string',
                    },
                ],
            },
            {
                path: '/api/mobile-configuration/:id',
                method: 'DELETE',
                description: 'Delete mobile configuration by ID; https://abpa-cloud.de/api/mobile-configuration/:id',
                params: [
                    {
                        name: 'id',
                        type: 'number',
                    },
                ],
                header: [
                    {
                        name: 'Authorization',
                        type: 'Bearer <Authorization Value>',
                    },
                ],
                body: [],
                response: [],
            },
        ]
    },
    {
        subheader: 'Organisation',
        items: [
            {
                path: '/api/organisation',
                method: 'GET',
                description: 'Get all organisations; https://abpa-cloud.de/api/api/organisation',
                params: [],
                header: [
                    {
                        name: 'Authorization',
                        type: 'Bearer <Authorization Value>',
                    },
                ],
                body: [],
                response: [
                    {
                        name: 'id',
                        type: 'number',
                    },
                    {
                        name: 'name',
                        type: 'string',
                    },
                ],
            },
            {
                path: '/api/organisation',
                method: 'POST',
                description: 'Create an organisation; https://abpa-cloud.de/api/api/organisation',
                params: [],
                header: [
                    {
                        name: 'Authorization',
                        type: 'Bearer <Authorization Value>',
                    },
                ],
                body: [
                    {
                        name: 'name',
                        type: 'string',
                    },
                ],
                response: [
                    {
                        name: 'id',
                        type: 'number',
                    },
                    {
                        name: 'name',
                        type: 'string',
                    },
                ],
            },
            {
                path: '/api/organisation/:id',
                method: 'PUT',
                description: 'Update organisation by ID; https://abpa-cloud.de/api/api/organisation/:id',
                params: [
                    {
                        name: 'id',
                        type: 'number',
                    },
                ],
                header: [
                    {
                        name: 'Authorization',
                        type: 'Bearer <Authorization Value>',
                    },
                ],
                body: [
                    {
                        name: 'name',
                        type: 'string',
                    },
                ],
                response: [
                    {
                        name: 'id',
                        type: 'number',
                    },
                    {
                        name: 'name',
                        type: 'string',
                    },
                ],
            },
            {
                path: '/api/organisation/:id',
                method: 'DELETE',
                description: 'Delete organisation by ID; https://abpa-cloud.de/api/api/organisation/:id',
                params: [
                    {
                        name: 'id',
                        type: 'number',
                    },
                ],
                header: [
                    {
                        name: 'Authorization',
                        type: 'Bearer <Authorization Value>',
                    },
                ],
                body: [],
                response: [],
            },
        ]
    },
    {
        subheader: 'Problempoint',
        items: [
            {
                path: '/api/problempoint',
                method: 'GET',
                description: 'Get all problempoints; https://abpa-cloud.de/api/api/problempoint',
                params: [],
                header: [
                    {
                        name: 'Authorization',
                        type: 'Bearer <Authorization Value>',
                    },
                ],
                body: [],
                response: [
                    {
                        name: 'id',
                        type: 'number',
                    },
                    {
                        name: 'name',
                        type: 'string',
                    },
                ],
            },
            {
                path: '/api/problempoint/:id',
                method: 'GET',
                description: 'Get problempoint by ID; https://abpa-cloud.de/api/api/problempoint/:id',
                params: [
                    {
                        name: 'id',
                        type: 'number',
                    },
                ],
                header: [
                    {
                        name: 'Authorization',
                        type: 'Bearer <Authorization Value>',
                    },
                ],
                body: [],
                response: [
                    {
                        name: 'id',
                        type: 'number',
                    },
                    {
                        name: 'name',
                        type: 'string',
                    },
                ],
            },
            {
                path: '/api/problempoint',
                method: 'POST',
                description: 'Create a problempoint; https://abpa-cloud.de/api/api/problempoint',
                params: [],
                header: [
                    {
                        name: 'Authorization',
                        type: 'Bearer <Authorization Value>',
                    },
                ],
                body: [
                    {
                        name: 'name',
                        type: 'string',
                    },
                ],
                response: [
                    {
                        name: 'id',
                        type: 'number',
                    },
                    {
                        name: 'name',
                        type: 'string',
                    },
                ],
            },
            {
                path: '/api/problempoint/:id',
                method: 'PUT',
                description: 'Update problempoint by ID; https://abpa-cloud.de/api/api/problempoint/:id',
                params: [
                    {
                        name: 'id',
                        type: 'number',
                    },
                ],
                header: [
                    {
                        name: 'Authorization',
                        type: 'Bearer <Authorization Value>',
                    },
                ],
                body: [
                    {
                        name: 'name',
                        type: 'string',
                    },
                ],
                response: [
                    {
                        name: 'id',
                        type: 'number',
                    },
                    {
                        name: 'name',
                        type: 'string',
                    },
                ],
            },
            {
                path: '/api/problempoint/:id',
                method: 'DELETE',
                description: 'Delete problempoint by ID; https://abpa-cloud.de/api/api/problempoint/:id',
                params: [
                    {
                        name: 'id',
                        type: 'number',
                    },
                ],
                header: [
                    {
                        name: 'Authorization',
                        type: 'Bearer <Authorization Value>',
                    },
                ],
                body: [],
                response: [],
            },
        ]
    },
    {
        subheader: 'Record',
        items: [
            {
                path: '/api/record',
                method: 'GET',
                description: 'Get all records; https://abpa-cloud.de/api/api/record',
                params: [],
                header: [
                    {
                        name: 'Authorization',
                        type: 'Bearer <Authorization Value>',
                    },
                ],
                body: [],
                response: [
                    {
                        name: 'id',
                        type: 'number',
                    },
                    {
                        name: 'videoFileName',
                        type: 'string',
                    },
                ],
            },
            {
                path: '/api/record/:id',
                method: 'GET',
                description: 'Get record by ID; https://abpa-cloud.de/api/api/record/:id',
                params: [
                    {
                        name: 'id',
                        type: 'number',
                    },
                ],
                header: [
                    {
                        name: 'Authorization',
                        type: 'Bearer <Authorization Value>',
                    },
                ],
                body: [],
                response: [
                    {
                        name: 'id',
                        type: 'number',
                    },
                    {
                        name: 'videoFileName',
                        type: 'string',
                    },
                ],
            },
            {
                path: '/api/record',
                method: 'POST',
                description: 'Create a record; https://abpa-cloud.de/api/api/record',
                params: [],
                header: [
                    {
                        name: 'Authorization',
                        type: 'Bearer <Authorization Value>',
                    },
                ],
                body: [
                    {
                        name: 'data',
                        type: 'object',
                    },
                ],
                response: [
                    {
                        name: 'id',
                        type: 'number',
                    },
                    {
                        name: 'videoFileName',
                        type: 'string',
                    },
                ],
            },
        ]
    },
    {
        subheader: 'RecordLocation',
        items: [
            {
                path: '/api/record-location',
                method: 'POST',
                description: 'Create a record location; https://abpa-cloud.de/api/api/record-location',
                params: [],
                body: [
                    {
                        name: 'data',
                        type: 'object',
                    },
                ],
                header: [
                    {
                        name: 'Authorization',
                        type: 'Bearer <Authorization Value>',
                    },
                ],
                response: [
                    {
                        name: 'id',
                        type: 'number',
                    },
                    {
                        name: 'timestamp',
                        type: 'string',
                    },
                ],
            },
        ]
    },
];