import { Typography, Box, Dialog, AppBar, Toolbar, IconButton, Table, TableBody, TableRow, TableCell, DialogContent, TextField } from '@mui/material';
import { Check, Close, EditOffOutlined, EditOutlined } from '@mui/icons-material';
import useEditContainerController from '../../../../hooks/useEditContainer.controller';
import './DetailedTableItem.css';

interface DetailedTableItemProps {
    item: any;
    isOpen: boolean;
    onClose: () => void;
    onUpdate?: (item: any) => void;
}

function DetailedTableItem(props: DetailedTableItemProps) {
    const { item, isOpen, onClose, onUpdate } = props;
    const {
        edit,
        editItem,
        open,
        openEditHandler,
        closeEditHandler,
        updateItemHandler,
        changeItemHandler,
        changeNestedItemHandler
    } = useEditContainerController({ item, onUpdate });

    return (
        <Dialog open={isOpen} className="content-dialog" classes={{ paper: "content-dialog-card" }}>
            <AppBar className="content-dialog-appbar" position="relative" elevation={1}>
                <Toolbar className="dialog-toolbar">
                    <Box display="flex" justifyContent="space-between" alignItems="center" width="100%" gap={10}>
                        <Box display="flex" justifyContent="space-between" alignItems="center" gap={1}>
                            <IconButton
                                className="dialog-button"
                                onClick={onClose}
                                aria-label="close"
                                edge="start"
                            >
                                <Close />
                            </IconButton>
                            <Typography variant="h6" component="div" className="dialog-title">
                                Details
                            </Typography>
                        </Box>
                        {edit ? (
                            <Box display="flex" justifyContent="space-between" alignItems="center" gap={1}>
                                <IconButton
                                    className="dialog-button"
                                    autoFocus
                                    onClick={updateItemHandler}
                                >
                                    <Check />
                                </IconButton>
                                <IconButton
                                    className="dialog-button"
                                    autoFocus
                                    onClick={closeEditHandler}
                                >
                                    <EditOffOutlined />
                                </IconButton>
                            </Box>
                        ) : (
                            <Box display="flex" justifyContent="space-between" alignItems="center" gap={1}>
                                <IconButton
                                    className="dialog-button"
                                    autoFocus
                                    onClick={openEditHandler}
                                >
                                    <EditOutlined />
                                </IconButton>
                            </Box>
                        )}
                    </Box>
                </Toolbar>
            </AppBar>
            <DialogContent className="content-dialog-body">
                <Table className="content-dialog-table">
                    <TableBody className="table-body">

                        {Object.keys(item).map((key: string, index: number) => (
                            <TableRow className="table-row" key={index}>
                                <TableCell className="table-cell header">
                                    {key}
                                </TableCell>
                                <TableCell className="table-cell content">

                                    {typeof item[key] === 'object' && item[key] !== null ? (
                                        edit ? (
                                            <TextField
                                                className="dialog-textfield"
                                                variant="filled"
                                                fullWidth
                                                type="number"
                                                value={editItem[key].id}
                                                classes={{ root: "textfield-root" }}
                                                InputProps={{ disableUnderline: true }}
                                                inputProps={{ inputMode: 'numeric', pattern: '[0-9]' }}
                                                onChange={(event) => changeNestedItemHandler(event, key)}
                                            />
                                        ) : (
                                            item[key].name
                                        )
                                    ) : key === 'createdAt' || key === 'updatedAt' ? (
                                        item[key]
                                    ) : (
                                        edit && key !== 'id' ? (
                                            <TextField
                                                className="dialog-textfield"
                                                variant="filled"
                                                fullWidth
                                                type="text"
                                                value={editItem[key]}
                                                classes={{ root: "textfield-root" }}
                                                InputProps={{ disableUnderline: true }}
                                                onChange={(event) => changeItemHandler(event, key)}
                                            />
                                        ) : (
                                            item[key]
                                        )
                                    )}

                                </TableCell>
                            </TableRow>
                        ))}

                    </TableBody>
                </Table>
            </DialogContent>
        </Dialog>
    )
}

export default DetailedTableItem;