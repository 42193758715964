import { useEffect, useState } from 'react';
import { Card, CardActions, CardContent, Typography, IconButton, List, ListItem, ListSubheader, CircularProgress } from '@mui/material';
import { ACCESS_TOKEN_NAME } from '../../../common/constants';
import PhonelinkLockIcon from '@mui/icons-material/PhonelinkLock';
import { AuthenticationService } from '../../../service/authentication/authentication.service';
import { FindInPageOutlined } from '@mui/icons-material';
import TokenTextField from '../../ui/TokenTextField/TokenTextField';
import { ApplicationName } from '../../../service/mobile-user/application-name.enum';
import { useDispatch } from 'react-redux';
import * as snackbarActions from '../../../store/actions/snackbar.actions';
import './Overview.css';

function Overview() {
  const [apiKey, setApiKey] = useState<string | null>(null);
  const [applicationTokens, setApplicationTokens] = useState<{ token: string, application: ApplicationName }[]>([]);
  const authenticationService = new AuthenticationService();
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setApiKey(localStorage.getItem(ACCESS_TOKEN_NAME));
  }, []);

  const generateApplicationToken = async () => {
    if (!apiKey) return;

    setApplicationTokens([]);
    setLoading(true);
    try {
      const responseAbpaToken = await authenticationService.generateApplicationToken(apiKey, ApplicationName.ABPA_MOBILE);
      setApplicationTokens((prevState) => (
        [...prevState, { token: responseAbpaToken.access_token, application: ApplicationName.ABPA_MOBILE }]
      ));

      const responseBaanFreiToken = await authenticationService.generateApplicationToken(apiKey, ApplicationName.BAAN_FREI_Mobile);
      setApplicationTokens((prevState) => (
        [...prevState, { token: responseBaanFreiToken.access_token, application: ApplicationName.BAAN_FREI_Mobile }]
      ));

      dispatch(snackbarActions.setSuccessMessage('Anwendungs Token wurden erfolgreich generiert') as any);
      console.log(applicationTokens);
    } catch (error) {
      console.error(error);
      dispatch(snackbarActions.setErrorMessage('Anwendungs Token konnte nicht generiert werden') as any);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="overview">
      <List>
        <ListSubheader className="subheader">Dokumentation & Richtlinien</ListSubheader>
        <ListItem className="list-item">
          <Card className="overview-card">
            <CardContent className="card-content">
              <Typography variant="body2" component="p">
                Wir empfehlen Ihnen, sich vor der Verwendung der API mit den Dokumentationen und Richtlinien vertraut zu machen.
              </Typography>
            </CardContent>
            <CardActions>
              <IconButton href="/documentation" className="icon-button">
                <FindInPageOutlined className="icon" color="primary" />
                <Typography variant="body1" className="text" color="primary">
                  Erfahre mehr
                </Typography>
              </IconButton>
            </CardActions>
          </Card>
        </ListItem>
        <ListSubheader className="subheader">API Key</ListSubheader>
        <ListItem className="list-item">
          <Card className="overview-card">
            <CardContent className="card-content">
              <Typography variant="body2" component="p">
                Hier finden Sie Ihren API Key. Dieser Key ist notwendig, um die API zu verwenden.
              </Typography>
              <TokenTextField token={apiKey} />
              {applicationTokens && applicationTokens.length > 0 && (
                <>
                  {applicationTokens.map((token) => (
                    <>
                      <Typography variant="body2" component="p">
                        Anwendungs Token für {token.application}
                      </Typography>
                      <TokenTextField key={token.application} token={token.token} />
                    </>
                  ))}
                </>
              )}
            </CardContent>
            <CardActions>
              {loading ? (
                <CircularProgress color="primary" size={20} />
              ) : (
                <IconButton className="icon-button" onClick={generateApplicationToken}>
                  <PhonelinkLockIcon className="icon" color="primary" />
                  <Typography variant="body1" className="text" color="primary">
                    Anwendungs Token generieren
                  </Typography>
                </IconButton>
              )}
            </CardActions>
          </Card>
        </ListItem>
      </List>
    </div>
  )
}

export default Overview;
