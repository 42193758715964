import { Typography, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import { ClassifiedGeoresultInterface, PropertyTypeInterface } from '../../../../../service/georesult/simplified-georesult.interface';
import './RoutingPopupItem.css';

interface RoutingPopupItemProps {
    title: string;
    subtitle?: string;
    tableData?: ClassifiedGeoresultInterface[];
    dataLabel?: PropertyTypeInterface[];
}

function RoutingPopupItem(props: RoutingPopupItemProps) {
    const { title, subtitle, tableData, dataLabel } = props;

    if (!subtitle && (!tableData || tableData.length === 0)) {
        return null;
    }

    return (
        <div className="popup-item">
            {subtitle && (
                <>
                    <Typography variant="h6" align="left" fontWeight={700} color="primary" className="content-title">
                        {title}
                    </Typography>
                    <Typography variant="subtitle1" align="left" fontWeight={400} className="content-subtitle">
                        {subtitle}
                    </Typography>
                </>
            )}

            {tableData && dataLabel && (
                <Table className="table">
                    <TableHead className="table-head">
                        <TableRow className="table-row">
                            <TableCell className="table-cell">{title}</TableCell>
                            <TableCell className="table-cell">Wahrscheinlichkeit</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody className="table-body">

                        {tableData.map((dataItem, index) => (
                            <TableRow key={index} className="table-row">
                                <TableCell className="table-cell">{dataLabel.find(dataLabelItem => dataLabelItem.id === dataItem.id)?.name}</TableCell>
                                <TableCell className="table-cell">{Math.round(dataItem.probability)}%</TableCell>
                            </TableRow>
                        ))}

                    </TableBody>
                </Table>
                        )}
        </div>
    )
}

export default RoutingPopupItem