import { List, ListItemButton, ListItemIcon, ListItemText, ListSubheader } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { routesRessources } from '../../../routes/routesRessources';
import SidebarHeader from '../SidebarHeader/SidebarHeader';
import './Sidebar.css';

interface SidebarProps {
    isDrawerOpen: boolean;
}

function Sidebar({ isDrawerOpen }: SidebarProps) {
    return (
        <div className={`sidebar${isDrawerOpen ? " active" : ""}`}>
            <SidebarHeader />
            <List>

                {routesRessources.map((routeFamily) => (
                    <>
                        <ListSubheader className="list-item-header" key={routeFamily.name}>{routeFamily.name}</ListSubheader>

                        {routeFamily.family.filter((route) => route.roles && route.roles.includes("administrator")).map((route) => (
                            <ListItemButton component={NavLink} to={route.path} className="list-item" key={route.path}>
                                <ListItemIcon className="list-item-icon">
                                    {route.icon}
                                </ListItemIcon>
                                <ListItemText primary={route.name} className="list-item-text" />
                            </ListItemButton>
                        ))}

                    </>
                ))}

            </List>
        </div>
    )
}

export default Sidebar;