import React from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Typography, ListItem, Table, TableContainer, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import { ArrowForwardIosOutlined } from '@mui/icons-material';
import { ApiDocumentationItemParamInterface } from '../../../../../assets/documentation/api-documentation/api-documentation.interface';
import './ItemRequirement.css';

interface ItemRequirementProps {
  title: string;
  requirement: ApiDocumentationItemParamInterface[];
}

function ItemRequirement({ title, requirement }: ItemRequirementProps) {
  return (
    <ListItem className="requirement-item">
      <Accordion className="accordion requirement" disabled={requirement.length === 0} disableGutters>
        <AccordionSummary className="accordion-summary" expandIcon={<ArrowForwardIosOutlined sx={{ fontSize: '1rem', color: "white" }} />}
          sx={{
            '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
              transform: 'rotate(90deg)',
            }
          }}>
          <Typography>{title}</Typography>
        </AccordionSummary>
        <AccordionDetails className="accordion-details">
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Datentyp</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>

                {requirement.map((requirementItem) => (
                  <TableRow>
                    <TableCell>{requirementItem.name}</TableCell>
                    <TableCell>{requirementItem.type}</TableCell>
                  </TableRow>
                ))}

              </TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>
    </ListItem>
  )
}

export default ItemRequirement