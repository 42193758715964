import { MobileConfigurationService } from '../../../../service/mobile-configuration/mobile-configuration.service';
import DataContainer from '../../../container/DataContainer/DataContainer';

function MobileConfigurations() {
    return (
        <DataContainer
            resourceService={new MobileConfigurationService()}
            title="Mobile Configurations"
            deleteAllowed={true}
            updateAllowed={true}
        />
    )
}

export default MobileConfigurations;