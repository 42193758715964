import React from 'react';
import { API_DOCUMENTATION } from '../../../assets/documentation/api-documentation/api-documentation';
import { List, ListSubheader, ListItem } from '@mui/material';
import { ApiDocumentationInterface } from '../../../assets/documentation/api-documentation/api-documentation.interface';
import DocumentationItem from './DocumentationItem/DocumentationItem';
import './Documentation.css';

function Documentation() {
    const requirementTitles = ["Params", "Header", "Body", "Response"];

    return (
        <div className="api-documentation-container">
            <List>

                {API_DOCUMENTATION.map((api: ApiDocumentationInterface) => (

                    <>
                        <ListSubheader className="subheader">{api.subheader}</ListSubheader>
                        <ListItem className="list-item">

                            {api.items.map((item) => {
                                return (
                                    <DocumentationItem
                                        item={item}
                                        requirementTitles={requirementTitles}
                                    />
                                )
                            }
                            )}

                        </ListItem>
                    </>

                )
                )}

            </List>
        </div>
    )
}

export default Documentation;
