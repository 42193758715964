import { useRef, useMemo } from 'react';
import { Polyline } from 'react-leaflet';
import { SimplifiedGeoresultCollectionInterface, PropertyTypeInterface } from '../../../../service/georesult/simplified-georesult.interface';
import RoutingPopup from '../RoutingPopup/RoutingPopup';
import { POLYLINE_INITIAL_WEIGHT, POLYLINE_HOVER_WEIGHT, POLYLINE_HOVER_COLOR, POLYLINE_INITIAL_OPACITY } from '../../../../common/constants';

interface RoutingLineProps {
    georesultCollection: SimplifiedGeoresultCollectionInterface;
    categoryDataLabel: PropertyTypeInterface[];
    classDataLabel: PropertyTypeInterface[];
    selectData: (data: SimplifiedGeoresultCollectionInterface) => void;
}

function RoutingLine(props: RoutingLineProps) {
    const { georesultCollection, categoryDataLabel, classDataLabel, selectData } = props;
    const polylineRef = useRef<any | null>(null);
    const { color, coordinates } = georesultCollection;

    const handleSelectData = () => {
        selectData(georesultCollection);
    }

    const eventHandlers = useMemo(
        () => ({
            mouseover() {
                polylineRef.current.setStyle({ color: POLYLINE_HOVER_COLOR, weight: POLYLINE_HOVER_WEIGHT });
                polylineRef.current.openPopup();
            },
            mouseout() {
                polylineRef.current.setStyle({ color: color, weight: POLYLINE_INITIAL_WEIGHT });
                polylineRef.current.closePopup();
            },
            click() {
                handleSelectData()
            }
        }),
        []
    );

    return (
        <Polyline
            ref={polylineRef}
            positions={coordinates.map(coord => [coord[1], coord[0]])}
            color={color}
            weight={POLYLINE_INITIAL_WEIGHT}
            opacity={POLYLINE_INITIAL_OPACITY}
            lineCap="square"
            lineJoin="round"
            eventHandlers={eventHandlers}
        >
            <RoutingPopup georesultCollection={georesultCollection} categoryDataLabel={categoryDataLabel} classDataLabel={classDataLabel} />
        </Polyline>
    );
}

export default RoutingLine;