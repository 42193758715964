import BackgroundImage from '../../../assets/images/background.jpg';
import Login from '../../pages/Login/Login';
import AuthInformation from '../../ui/AuthInformation/AuthInformation';
import './AuthenticationLayout.css';

function AuthenticationLayout() {
    return (
        <div className="login-content">
            <div className="background">
                <img src={BackgroundImage} alt="Background" />
                <div className="overlay"></div>
                <div className="content">
                    <AuthInformation />
                    <Login />
                </div>
            </div>
        </div>
    )
}

export default AuthenticationLayout;