import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
    palette: {
        primary: {
            main: '#BCD2C5',
        },
        secondary: {
            main: '#F5F2ED',
        },
        divider: '#e0e0e0',
    },
    typography: {
        fontFamily: ['Plus Jakarta Sans', 'sans-serif'].join(','),
        fontSize: 15,
    },
});