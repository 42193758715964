import { Typography } from '@mui/material';
import { ApiInstructionsInterface } from '../../../../assets/documentation/api-instructions/api-instructions.interface';
import './InstructionItem.css';

interface InstructionItemInterface {
    id: number;
    apiInstruction: ApiInstructionsInterface;
}

function InstructionItem(props: InstructionItemInterface) {
    const { id, apiInstruction } = props;
    const { headlineType, headline, text } = apiInstruction;

    return (
        <div className={headlineType === 'headline' ? 'instruction-item' : 'instruction-item sub-headline'} id={`instruction-item-${id}`}>
            <Typography variant={headlineType === 'headline' ? 'h4' : 'h6'} component={headlineType === 'headline' ? 'h4' : 'h6'} className="title">
                {headline}
            </Typography>
            <Typography variant="body1" component="p" className="description">
                {text}
            </Typography>
        </div>
    )
}

export default InstructionItem;