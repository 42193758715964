import React from 'react';
import './PrivacyPolicy.css';

interface PrivacyPolicyProps {
  type: "abpa" | "baanfrei";
}

function PrivacyPolicy(props: PrivacyPolicyProps) {
  const { type } = props;

  if(type === "abpa") {
    return (
      <div className="privacy-policy">
      <h1>Datenschutzerkl&auml;rung - Hochschule Furtwangen</h1>
      <p><strong><span>An der Hochschule Furtwangen wird dem Datenschutz eine hohe Priorit&auml;t zugeschrieben. Gleiches gilt f&uuml;r die Forschungsarbeiten des Projekts (ABPA). In Rahmen dessen wird die hier angebotene App entwickelt und verteilt. Die App arbeitet mit standortbezogenen Daten, womit der Schutz der Privatsph&auml;re und den gesetzlichen Datenschutzbestimmungen eine hohe Bedeutung zukommt. Im Folgenden wird dargelegt, wie die aufgenommenen bzw. hinterlegten, pers&ouml;nlichen Daten, die f&uuml;r oder &uuml;ber diese App erhoben und verarbeitet werden, gehandhabt werden.</span></strong></p>
      <p><span>Verantwortliche Stelle im Sinne der Datenschutzgesetze ist die Hochschule Furtwangen, Robert-Gerwig-Platz 1 in 78120 Furtwangen im Schwarzwald.</span></p>
      <h2>
        1. Datenerhebung, -verarbeitung und -nutzung
      </h2>

      <p><span>Innerhalb des Forschungsprojekts ABPA werden personenbezogene Daten erhoben, verarbeitet und ausgewertet, um R&uuml;ckschl&uuml;sse auf das Mobilit&auml;tsverhalten der gesamten Nutzergruppe ziehen zu k&ouml;nnen. Die App nimmt die folgenden Daten auf:</span></p>
      <ul>
        <li><span>Standortkoordinaten</span></li>
        <li><span>Zeitstempel</span></li>
        <li><span>Geschwindigkeit</span></li>
        <li><span>User_Token bzw. Nutzer-ID</span></li>
        <li><span>Videomaterial bzw. Videoaufnahmen</span></li>
        <li><span>Mikrofonaufnahme</span></li>
      </ul>
      <p><span>Diese Angaben werden mehrmals pro Minute aufgenommen und an die Server des Rechenzentrums der Hochschule geschickt, sobald eine Internetverbindung vorliegt. Die &Uuml;bertragung verl&auml;uft verschl&uuml;sselt per HTTPS-Zertifikat und die Server der Hochschule werden nach landesrechtlichen Bestimmungen betrieben. Anderweitige pers&ouml;nliche Daten werden nicht aufgenommen. Somit k&ouml;nnen die standortbezogenen Daten nicht konkret mit Namen und Anschrift der jeweiligen Person in Bezug gebracht werden. Stattdessen wird jedem Nutzer automatisch eine ID zugeteilt, wor&uuml;ber individuelle Benachrichtigung in der Smartphone-App versendet werden k&ouml;nnen. Diese werden genutzt, um die Nutzer &amp; Nutzerinnen &uuml;ber Neuigkeiten zu informieren.</span></p>
      <p><span>Die Daten werden zun&auml;chst serverseitig gespeichert und sobald erforderlich gesichert. Die Verarbeitung erfolgt ausschlie&szlig;lich intern innerhalb des Projektteams und es werden keine externen Cloud-L&ouml;sungen o.&auml;. genutzt. Demzufolge erfolgt keinerlei Weitergabe an Dritte, was die Sicherheit der aufgenommenen Daten erh&ouml;ht. Die Standortdaten dienen dazu, Aussagen &uuml;ber das Mobilit&auml;tsverhalten der gesamten Nutzergruppe abzuleiten. Daher werden stets alle Teilnehmenden gemeinsam betrachtet und es finden keine detaillierten Untersuchungen der Bewegungen einzelner Personen statt. Diese Aggregation erlaubt es, dass lediglich R&uuml;ckschl&uuml;sse auf die gesamte Gruppe und nicht etwa auf Individuen getroffen werden k&ouml;nnen. Zuk&uuml;nftig ist zudem geplant, besonders oft ausgesuchte Orte (wie z.B. Haus &amp; Wohnung) besonders zu sch&uuml;tzen, indem die Aufnahme der Standortdaten automatisch bereits in gewisser Distanz zum entsprechenden Zielort beendet wird. Diese Vorkehrung muss momentan jedoch erst noch entwickelt werden.</span></p>
      <p><span>Alle Projektarbeiten finden innerhalb eines vom Verkehrsministerium des Landes Baden-W&uuml;rttemberg gef&ouml;rderten Projektes statt und unterliegen somit den Regularien der &ouml;ffentlichen Hand. Die aufgenommenen Daten werden sp&auml;testens mit Ablauf von f&uuml;nf Jahren nach Ende des Projekts (geplantes Projektende Dezember 2023) gel&ouml;scht. Daf&uuml;r ist der aufgenommene Zeitstempel ma&szlig;geblich, der eine automatische Einrichtung dieses Vorgangs erm&ouml;glicht.</span></p>
      <p><span>Zu diesen Zwecken werden personenbezogene Daten nur erhoben, verarbeitet oder genutzt, sobald eine Einwilligung des Nutzers oder der Nutzerin vorliegt. Diesbez&uuml;glich findet eine Abfrage bei erstmaligem Start der App statt. Dazu erfolgt eine Abfrage der Ortungsfreigabe des Smartphones. Die Ortungsfreigabe ist notwendig zur Nutzung der App. Die Einwilligung kann jederzeit in der App in einem extra Reiter f&uuml;r Informationen/Datenschutz/Nutzerbedingungen mit Wirkung f&uuml;r die Zukunft widerrufen werden. Zus&auml;tzlich ist es m&ouml;glich, die Aufnahme von standortbezogenen Daten innerhalb der App tempor&auml;r zu deaktivieren. Hierzu ist die Bet&auml;tigung einer Schaltfl&auml;che auf dem Hauptbildschirm erforderlich. Eine erneute Bet&auml;tigung dieser Schaltfl&auml;che reaktiviert die Aufnahme standortbezogener Daten.</span></p>
      <p><span>Die App kann auch genutzt werden, ohne dass personenbezogene Daten erhoben werden. Allerdings k&ouml;nnen dann nur Informationen (wie z.B. die eigene Position im Ranking) eingesehen werden. Ein An- und Abschalten der Datenerhebung ist sowohl tempor&auml;r als auch dauerhaft m&ouml;glich. Sollte lediglich die tempor&auml;re Abschaltung gew&auml;hlt worden sein, wird der Nutzer bzw. die Nutzerin einmal pro Tag daran erinnert, dass momentan keine Daten aufgenommen werden. Diese Benachrichtigung erfolgt &uuml;ber den Notification-Channel des Endger&auml;ts und kommt fr&uuml;hestens 2 Stunden, nachdem die tempor&auml;re Abschaltung erfolgt ist, zum Tragen.</span></p>
      <h2>2. Automatisch erfasste Daten    </h2>

      <p><span>&nbsp;</span></p>
      <p><strong><span>Analyseprogramme</span></strong></p>
      <p><span>Wir greifen auf die Angaben der Google Play Store Konsole zur&uuml;ck, um die App zu verbessern und Fehler, wie z.B. Fehlverhalten oder Abst&uuml;rze zu analysieren und zuk&uuml;nftig zu vermeiden. Die dazu erfassten Daten werden entsprechend der allgemeinen Bedingungen f&uuml;r Entwickler von Google gehandhabt. Wir werden &uuml;ber Abst&uuml;rze benachrichtigt und sehen die Codezeile, welche den Absturz verursacht hat, die Art des mobilen Endger&auml;ts und des installierten Betriebssystems, die Gr&ouml;&szlig;e des freien Arbeitsspeichers und Flash-Speichers, sowie ob das Betriebssystem einem &bdquo;Jailbreak&ldquo; unterzogen worden ist. Diese Daten werden verwendet, um den Fehler so gut wie m&ouml;glich reproduzieren und danach in einer der n&auml;chsten Versionen beheben zu k&ouml;nnen. Die von Google selbst gespeicherten Daten sind in der Datenschutzrichtlinie (</span><span><a href="https://policies.google.com/privacy?hl=de&gl=lu">https://policies.google.com/privacy?hl=de&amp;gl=lu</a></span><span>) sowie den Nutzungsbedingungen des Play Stores (</span><span><a href="https://play.google.com/intl/de_lu/about/play-terms/">https://play.google.com/intl/de_lu/about/play-terms/</a></span><span>) zu finden.&nbsp;</span></p>
      <p><strong><span>Google Analytics SDK</span></strong></p>
      <p><span>Innerhalb des Projekts wird auf die durch den Android Playstore bereitgestellten Daten zur&uuml;ckgegriffen, um die Verwendung der App zu analysieren, damit sie st&auml;ndig verbessert werden kann. &Uuml;ber diese grundlegenden Daten des Playstores hinaus wird nicht auf Google Analytics (von Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA (&quot;Google&quot;) bereitgestellt) zur&uuml;ckgegriffen.&nbsp;</span></p>
      <h2><strong><span>3. Erfassung von Standortdaten</span></strong>    </h2>

      <p><span>Die App kann &uuml;ber GPS/Wi-Fi den Standort ermitteln, wenn bei der Installation der App diese Freigabe in den Einstellungen des Betriebssystems angenommen wurde. Die Freigabe kann danach jederzeit in der App sowohl dauerhaft als auch tempor&auml;r deaktiviert und wieder aktiviert werden.</span></p>
      <h2>4. Welche Daten werden bei einer Registrierung erfasst?    </h2>

      <p><span>Bei der Registrierung f&uuml;r die Nutzung der ABPA-App werden zus&auml;tzlich zur Kennung der Firebase-ID einige wenige personenbezogene Daten erfasst. Dazu z&auml;hlen die folgenden Angaben:</span></p>
      <ul>
        <li><span>Art der Organisation (Hochschulmitglied, Projektpartner oder Anderes)</span></li>
        <li><span>Vergabe eines Alias (optional)</span></li>
      </ul>
      <p><span>Beide Informationen erlauben es nicht, R&uuml;ckschl&uuml;sse auf die jeweilige Person zu ziehen. Die damit verbundenen Datenaufnahmen des Standorts erfolgen somit prinzipiell anonym. Die Art der Organisation dient dazu, die Auswertungen des Projekts zu verfeinern. Dazu steht es den Teilnehmenden offen, sich selbst einen Alias bzw. einen Spitznamen zu vergeben.</span></p>
      <h2><span>5. Push Nachrichten</span>   </h2>

      <p><span>Die App nutzt Push Nachrichten, um die Nutzer und Nutzerinnen auf Neuigkeiten aufmerksam zu machen. Dazu z&auml;hlen die folgenden Angaben:</span></p>
      <ul>
        <li><span>Status des Trackings (An oder Aus)</span></li>
        <li><span>Aktualisierungen und Updates der App</span></li>
        <li><span>Aktualisierungen der Datenschutzbestimmungen und Nutzungsbedingungen</span></li>
        <li><span>Informationen und Meldungen zu Neuigkeiten</span></li>
      </ul>
      <p><span>Die Push Nachrichten k&ouml;nnen jederzeit in den Einstellungen des Betriebssystems deaktiviert und wieder aktiviert werden.</span></p>
      <h2><span>6. &Uuml;bermittlung personenbezogener Daten an Dritte/Datenumgang durch Dritte</span>    </h2>

      <p><span>Innerhalb der Projektarbeiten von ABPA werden zu keinem Zeitpunkt personenbezogene Daten nach Au&szlig;en bzw. an Dritte weitergegeben. Einzig die genutzten Funktionen und Services von Google bilden diesbez&uuml;glich die Ausnahme. An entsprechender Stelle wurde bereits an diese Dienste und die damit verbundenen Datenschutz- und Nutzungsbestimmungen verwiesen.</span></p>
      <h2><span>7. Sicherheit</span> </h2>

      <p><span>Wir setzen angemessene und dem aktuellen Stand der Technik entsprechende technische und organisatorische Sicherheitsma&szlig;nahmen ein, um die Daten der Nutzer/innen gegen Manipulationen, Verlust, Zerst&ouml;rung und gegen den Zugriff unberechtigter Personen zu sch&uuml;tzen. Die Sicherheitsma&szlig;nahmen werden entsprechend der technologischen Entwicklung fortlaufend verbessert. Ihre personenbezogenen Daten werden auf einem besonders gesch&uuml;tzten Server in der Europ&auml;ischen Union gespeichert. Wir sind um alle notwendigen, technischen und organisatorischen Sicherheitsma&szlig;nahmen bem&uuml;ht, um Ihre personenbezogenen Daten so zu speichern, dass sie vor unberechtigtem Zugang und Missbrauch gesch&uuml;tzt sind. Unsere Mitarbeiter sind verpflichtet, beim Umgang mit Daten die Regelungen des TMG und des BDSG zu beachten und sind im Bedarfsfalle nach &sect; 5 BDSG auf die Einhaltung des Datengeheimnisses verpflichtet. Um die Sicherheit Ihrer Daten bei der &Uuml;bertragung zu sch&uuml;tzen, verwenden wir h&auml;ufig Verschl&uuml;sselungsverfahren (z. B. SSL). Unsere Server sind mittels Firewalls und Virenschutz gesichert. Back-up und Recovery sowie Rollen- und Berechtigungskonzepte sind f&uuml;r uns selbstverst&auml;ndlich. Durch das Projekt erhobene Daten werden zu keinem Zeitpunkt in unbearbeiteter Form an Dritte weitergeben, es sei denn die Hochschule Furtwangen ist dazu aufgrund gesetzlicher Vorschriften verpflichtet.</span></p>
      <h2><span>8. Rechte auf Auskunft, Berichtigung, Sperrung, L&ouml;schung und Widerspruch</span>    </h2>

      <p><span>Sie haben das Recht, jederzeit Auskunft &uuml;ber Ihre, innerhalb des Projekts ABPA aufgenommenen, personenbezogenen Daten zu erhalten. Soweit notwendig, wird Ihre Identit&auml;t verifiziert, bevor eine Auskunft erteilt werden kann. Dazu dient die Kennung, welche Sie innerhalb der Installation der App zugeteilt bekommen haben.&nbsp;</span></p>
      <p><span>Ebenso haben Sie das Recht auf Berichtigung, Sperrung oder L&ouml;schung Ihrer personenbezogenen Daten. Bitte wenden Sie sich dazu an das Team des Projekts ABPA. Wenn die L&ouml;schung mit den gesetzlichen Aufbewahrungspflichten in Konflikt steht, werden die Daten entsprechend gesperrt. Sie k&ouml;nnen &Auml;nderungen oder den Widerruf einer Einwilligung durch entsprechende Mitteilung an uns mit Wirkung f&uuml;r die Zukunft vornehmen.</span></p>
      <h2><span>9. &Auml;nderung unserer Datenschutzerkl&auml;rung</span> </h2>

      <p><span>Sofern erforderlich, wird diese Datenschutzerkl&auml;rung w&auml;hrend des Verlaufs des Projekts angepasst. Dies dient einerseits dazu stets aktuelle, rechtliche Anforderungen einhalten zu k&ouml;nnen sowie Verbesserungen hinsichtlich des Konzepts und der technischen M&ouml;glichkeiten bez&uuml;glich des Datenschutzes vornehmen zu k&ouml;nnen. Gleiches gilt, falls neue Funktionen oder Leistungen in die App implementiert werden. F&uuml;r Ihre weitere Verwendung der App gilt dann die neue Datenschutzerkl&auml;rung, welche nach Aktualisierung stets erneut zu best&auml;tigen ist. Wird der &Auml;nderung widersprochen bzw. bleibt eine erneute Einwilligung aus, wird das Tracking der App inaktiv geschalten. Diese Funktion kann erst reaktiviert werden, sofern die neue Erkl&auml;rung akzeptiert worden ist.&nbsp;</span></p>
      <p><span>Die Rechte auf Auskunft, Berichtigung, Sperrung, L&ouml;schung und Widerspruch bleiben von einer &Auml;nderung unber&uuml;hrt.</span></p>
      <h2><span>10. Partner des Projekts</span>   </h2>

      <p><span>Neben der Hochschule Furtwangen sind die Hochschule Offenburg und die Outdooractive AG das in das Projekt eingebunden. Das BMDV ist Mittelgeber des Projekts und hat dementsprechend Vorgaben f&uuml;r die Ver&ouml;ffentlichung der Projektergebnisse erteilt. Mit Abschluss der Projektergebnisse werden Ver&ouml;ffentlichungen frei zug&auml;nglich im Open-Access-Format herausgegeben. Die Publikationen werden keine Rohdaten beinhalten und werden lediglich Kartierungen und Statistiken in f&uuml;r die Gesamtheit aller Teilnehmenden aggregierter Form beinhalten. Daraus folgt, dass stets das Mobilit&auml;tsverhalten mehrerer hundert Personen gleichzeitig untersucht wird und somit keine R&uuml;ckschl&uuml;sse auf einzelne Personen m&ouml;glich sind.&nbsp;</span></p>
      <p><span>Es wird darauf hingewiesen, dass die Partner in der Regel eigene Datenschutzbestimmungen haben, die au&szlig;erhalb des Einflusses der Hochschule bzw. der Projektarbeiten stehen. Jegliche Haftung und Verantwortung f&uuml;r die Datenschutzbestimmungen der Partner ist somit ausgeschlossen.</span></p>
      <h2><span >11. Ansprechpartner f&uuml;r den Datenschutz</span>   </h2>

      <p><span>Wenn Sie Fragen hinsichtlich der Verarbeitung der personenbezogenen Daten in der App haben, k&ouml;nnen Sie sich sowohl an das Projektteam als auch den Datenschutzbeauftragten der Hochschule wenden. Beide Anlaufstellen stehen f&uuml;r Auskunftsersuche, Anregungen oder Beschwerden zur Verf&uuml;gung.</span></p>
      <p><span>Wenn Sie Fragen hinsichtlich der Verarbeitung der personenbezogenen Daten in der App haben, k&ouml;nnen Sie sich sowohl an das Projektteam als auch den Datenschutzbeauftragten der Hochschule wenden. Beide Anlaufstellen stehen f&uuml;r Auskunftsersuche, Anregungen oder Beschwerden zur Verf&uuml;gung.</span></p>
      <p ><span>Team des Forschungsprojekts ABPA an der Hochschule Furtwangen&nbsp;Robert-Gerwig-Platz 1&nbsp;78120 Furtwangen im Schwarzwald&nbsp;07723 920-2129&nbsp;NICOLAS.MAHN(AT)HFU.DE</span></p>
      <p ><span>&nbsp;</span></p>
      <p ><span>&nbsp;</span></p>

    </div>
    );
  } 
  return (
    <div className="privacy-policy">
      <h1>Datenschutzerkl&auml;rung - Hochschule Furtwangen</h1>
      <p><strong><span>An der Hochschule Furtwangen wird dem Datenschutz eine hohe Priorit&auml;t zugeschrieben. Gleiches gilt f&uuml;r die Forschungsarbeiten des Projekts (BaAn-frei). In Rahmen dessen wird die hier angebotene App entwickelt und verteilt. Die App arbeitet mit standortbezogenen Daten, womit der Schutz der Privatsph&auml;re und den gesetzlichen Datenschutzbestimmungen eine hohe Bedeutung zukommt. Im Folgenden wird dargelegt, wie die aufgenommenen bzw. hinterlegten, pers&ouml;nlichen Daten, die f&uuml;r oder &uuml;ber diese App erhoben und verarbeitet werden, gehandhabt werden.</span></strong></p>
      <p><span>Verantwortliche Stelle im Sinne der Datenschutzgesetze ist die Hochschule Furtwangen, Robert-Gerwig-Platz 1 in 78120 Furtwangen im Schwarzwald.</span></p>
      <h2>
        1. Datenerhebung, -verarbeitung und -nutzung
      </h2>

      <p><span>Innerhalb des Forschungsprojekts BaAn-frei werden personenbezogene Daten erhoben, verarbeitet und ausgewertet, um R&uuml;ckschl&uuml;sse auf das Mobilit&auml;tsverhalten der gesamten Nutzergruppe ziehen zu k&ouml;nnen. Die App nimmt die folgenden Daten auf:</span></p>
      <ul>
        <li><span>Standortkoordinaten</span></li>
        <li><span>Zeitstempel</span></li>
        <li><span>Geschwindigkeit</span></li>
        <li><span>User_Token bzw. Nutzer-ID</span></li>
        <li><span>Videomaterial bzw. Videoaufnahmen</span></li>
        <li><span>Mikrofonaufnahme</span></li>
      </ul>
      <p><span>Diese Daten werden mehrfach pro Minute erfasst und bei bestehender Internetverbindung an die Server des Rechenzentrums eines externen Anbieters übermittelt. Die &Uuml;bertragung verl&auml;uft verschl&uuml;sselt per HTTPS-Zertifikat und die Server der Hochschule werden nach landesrechtlichen Bestimmungen betrieben. Anderweitige pers&ouml;nliche Daten werden nicht aufgenommen. Somit k&ouml;nnen die standortbezogenen Daten nicht konkret mit Namen und Anschrift der jeweiligen Person in Bezug gebracht werden. Stattdessen wird jedem Nutzer automatisch eine ID zugeteilt, wor&uuml;ber individuelle Benachrichtigung in der Smartphone-App versendet werden k&ouml;nnen. Diese werden genutzt, um die Nutzer &amp; Nutzerinnen &uuml;ber Neuigkeiten zu informieren.</span></p>
      <p><span>Die Daten werden zun&auml;chst serverseitig gespeichert und sobald erforderlich gesichert. Die Verarbeitung erfolgt ausschlie&szlig;lich intern innerhalb des Projektteams und es werden keine externen Cloud-L&ouml;sungen o.&auml;. genutzt. Demzufolge erfolgt keinerlei Weitergabe an Dritte, was die Sicherheit der aufgenommenen Daten erh&ouml;ht. Die Standortdaten dienen dazu, Aussagen &uuml;ber das Mobilit&auml;tsverhalten der gesamten Nutzergruppe abzuleiten. Daher werden stets alle Teilnehmenden gemeinsam betrachtet und es finden keine detaillierten Untersuchungen der Bewegungen einzelner Personen statt. Diese Aggregation erlaubt es, dass lediglich R&uuml;ckschl&uuml;sse auf die gesamte Gruppe und nicht etwa auf Individuen getroffen werden k&ouml;nnen. Zuk&uuml;nftig ist zudem geplant, besonders oft ausgesuchte Orte (wie z.B. Haus &amp; Wohnung) besonders zu sch&uuml;tzen, indem die Aufnahme der Standortdaten automatisch bereits in gewisser Distanz zum entsprechenden Zielort beendet wird. Diese Vorkehrung muss momentan jedoch erst noch entwickelt werden.</span></p>
      <p><span>Alle Projektarbeiten finden innerhalb eines vom Verkehrsministerium des Landes Baden-W&uuml;rttemberg gef&ouml;rderten Projektes statt und unterliegen somit den Regularien der &ouml;ffentlichen Hand. Die aufgenommenen Daten werden sp&auml;testens mit Ablauf von f&uuml;nf Jahren nach Ende des Projekts (geplantes Projektende Oktober 2025) gel&ouml;scht. Daf&uuml;r ist der aufgenommene Zeitstempel ma&szlig;geblich, der eine automatische Einrichtung dieses Vorgangs erm&ouml;glicht.</span></p>
      <p><span>Zu diesen Zwecken werden personenbezogene Daten nur erhoben, verarbeitet oder genutzt, sobald eine Einwilligung des Nutzers oder der Nutzerin vorliegt. Diesbez&uuml;glich findet eine Abfrage bei erstmaligem Start der App statt. Dazu erfolgt eine Abfrage der Ortungsfreigabe des Smartphones. Die Ortungsfreigabe ist notwendig zur Nutzung der App. Die Einwilligung kann jederzeit in der App in einem extra Reiter f&uuml;r Informationen/Datenschutz/Nutzerbedingungen mit Wirkung f&uuml;r die Zukunft widerrufen werden. Zus&auml;tzlich ist es m&ouml;glich, die Aufnahme von standortbezogenen Daten innerhalb der App tempor&auml;r zu deaktivieren. Hierzu ist die Bet&auml;tigung einer Schaltfl&auml;che auf dem Hauptbildschirm erforderlich. Eine erneute Bet&auml;tigung dieser Schaltfl&auml;che reaktiviert die Aufnahme standortbezogener Daten.</span></p>
      <p><span>Die App kann auch genutzt werden, ohne dass personenbezogene Daten erhoben werden. Allerdings k&ouml;nnen dann nur Informationen (wie z.B. die eigene Position im Ranking) eingesehen werden. Ein An- und Abschalten der Datenerhebung ist sowohl tempor&auml;r als auch dauerhaft m&ouml;glich. Sollte lediglich die tempor&auml;re Abschaltung gew&auml;hlt worden sein, wird der Nutzer bzw. die Nutzerin einmal pro Tag daran erinnert, dass momentan keine Daten aufgenommen werden. Diese Benachrichtigung erfolgt &uuml;ber den Notification-Channel des Endger&auml;ts und kommt fr&uuml;hestens 2 Stunden, nachdem die tempor&auml;re Abschaltung erfolgt ist, zum Tragen.</span></p>
      <h2>2. Automatisch erfasste Daten    </h2>

      <p><span>&nbsp;</span></p>
      <p><strong><span>Analyseprogramme</span></strong></p>
      <p><span>Wir greifen auf die Angaben der Google Play Store Konsole zur&uuml;ck, um die App zu verbessern und Fehler, wie z.B. Fehlverhalten oder Abst&uuml;rze zu analysieren und zuk&uuml;nftig zu vermeiden. Die dazu erfassten Daten werden entsprechend der allgemeinen Bedingungen f&uuml;r Entwickler von Google gehandhabt. Wir werden &uuml;ber Abst&uuml;rze benachrichtigt und sehen die Codezeile, welche den Absturz verursacht hat, die Art des mobilen Endger&auml;ts und des installierten Betriebssystems, die Gr&ouml;&szlig;e des freien Arbeitsspeichers und Flash-Speichers, sowie ob das Betriebssystem einem &bdquo;Jailbreak&ldquo; unterzogen worden ist. Diese Daten werden verwendet, um den Fehler so gut wie m&ouml;glich reproduzieren und danach in einer der n&auml;chsten Versionen beheben zu k&ouml;nnen. Die von Google selbst gespeicherten Daten sind in der Datenschutzrichtlinie (</span><span><a href="https://policies.google.com/privacy?hl=de&gl=lu">https://policies.google.com/privacy?hl=de&amp;gl=lu</a></span><span>) sowie den Nutzungsbedingungen des Play Stores (</span><span><a href="https://play.google.com/intl/de_lu/about/play-terms/">https://play.google.com/intl/de_lu/about/play-terms/</a></span><span>) zu finden.&nbsp;</span></p>
      <p><strong><span>Google Analytics SDK</span></strong></p>
      <p><span>Innerhalb des Projekts wird auf die durch den Android Playstore bereitgestellten Daten zur&uuml;ckgegriffen, um die Verwendung der App zu analysieren, damit sie st&auml;ndig verbessert werden kann. &Uuml;ber diese grundlegenden Daten des Playstores hinaus wird nicht auf Google Analytics (von Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA (&quot;Google&quot;) bereitgestellt) zur&uuml;ckgegriffen.&nbsp;</span></p>
      <h2><strong><span>3. Erfassung von Standortdaten</span></strong>    </h2>

      <p><span>Die App kann &uuml;ber GPS/Wi-Fi den Standort ermitteln, wenn bei der Installation der App diese Freigabe in den Einstellungen des Betriebssystems angenommen wurde. Die Freigabe kann danach jederzeit in der App sowohl dauerhaft als auch tempor&auml;r deaktiviert und wieder aktiviert werden.</span></p>
      <h2>4. Welche Daten werden bei einer Registrierung erfasst?    </h2>

      <p><span>Die Registrierung für die Nutzung der BaAn-frei-App ist freiwillig und kann innerhalb der Anwendung übersprungen werden. Falls dennoch eine Registrierung vorgenommen wird, werden einige wenige personenbezogene Daten erfasst. Dazu zählen folgende Angaben:</span></p> <ul> <li><span>E-Mail-Adresse</span></li> <li><span>Vorname und Nachname – der Nachname kann in abgekürzter Form oder vollständig angegeben werden</span></li> </ul>
      <p><span>Sofern keine Registrierung vorgenommen wird, lassen sich keine Rückschlüsse auf die jeweilige Person ziehen. Die damit verbundenen Standortdaten werden daher grundsätzlich anonym erfasst. Teilnehmenden bietet sich außerdem jederzeit die Möglichkeit, einen Alias oder Spitznamen zu vergeben, um die Nutzung trotz Registrierung weiter zu anonymisieren.</span></p>
      <h2><span>5. Push Nachrichten</span>   </h2>

      <p><span>Die App nutzt Push Nachrichten, um die Nutzer und Nutzerinnen auf Neuigkeiten aufmerksam zu machen. Dazu z&auml;hlen die folgenden Angaben:</span></p>
      <ul>
        <li><span>Status des Trackings (An oder Aus)</span></li>
        <li><span>Aktualisierungen und Updates der App</span></li>
        <li><span>Aktualisierungen der Datenschutzbestimmungen und Nutzungsbedingungen</span></li>
        <li><span>Informationen und Meldungen zu Neuigkeiten</span></li>
      </ul>
      <p><span>Die Push Nachrichten k&ouml;nnen jederzeit in den Einstellungen des Betriebssystems deaktiviert und wieder aktiviert werden.</span></p>
      <h2><span>6. &Uuml;bermittlung personenbezogener Daten an Dritte/Datenumgang durch Dritte</span>    </h2>

      <p><span>Innerhalb der Projektarbeiten von BaAn-frei werden zu keinem Zeitpunkt personenbezogene Daten nach Au&szlig;en bzw. an Dritte weitergegeben. Einzig die genutzten Funktionen und Services von Google bilden diesbez&uuml;glich die Ausnahme. An entsprechender Stelle wurde bereits an diese Dienste und die damit verbundenen Datenschutz- und Nutzungsbestimmungen verwiesen.</span></p>
      <h2><span>7. Sicherheit</span> </h2>

      <p><span>Wir setzen angemessene und dem aktuellen Stand der Technik entsprechende technische und organisatorische Sicherheitsma&szlig;nahmen ein, um die Daten der Nutzer/innen gegen Manipulationen, Verlust, Zerst&ouml;rung und gegen den Zugriff unberechtigter Personen zu sch&uuml;tzen. Die Sicherheitsma&szlig;nahmen werden entsprechend der technologischen Entwicklung fortlaufend verbessert. Ihre personenbezogenen Daten werden auf einem besonders gesch&uuml;tzten Server in der Europ&auml;ischen Union gespeichert. Wir sind um alle notwendigen, technischen und organisatorischen Sicherheitsma&szlig;nahmen bem&uuml;ht, um Ihre personenbezogenen Daten so zu speichern, dass sie vor unberechtigtem Zugang und Missbrauch gesch&uuml;tzt sind. Unsere Mitarbeiter sind verpflichtet, beim Umgang mit Daten die Regelungen des TMG und des BDSG zu beachten und sind im Bedarfsfalle nach &sect; 5 BDSG auf die Einhaltung des Datengeheimnisses verpflichtet. Um die Sicherheit Ihrer Daten bei der &Uuml;bertragung zu sch&uuml;tzen, verwenden wir h&auml;ufig Verschl&uuml;sselungsverfahren (z. B. SSL). Unsere Server sind mittels Firewalls und Virenschutz gesichert. Back-up und Recovery sowie Rollen- und Berechtigungskonzepte sind f&uuml;r uns selbstverst&auml;ndlich. Durch das Projekt erhobene Daten werden zu keinem Zeitpunkt in unbearbeiteter Form an Dritte weitergeben, es sei denn die Hochschule Furtwangen ist dazu aufgrund gesetzlicher Vorschriften verpflichtet.</span></p>
      <h2><span>8. Rechte auf Auskunft, Berichtigung, Sperrung, L&ouml;schung und Widerspruch</span>    </h2>

      <p><span>Sie haben das Recht, jederzeit Auskunft &uuml;ber Ihre, innerhalb des Projekts BaAn-frei aufgenommenen, personenbezogenen Daten zu erhalten. Soweit notwendig, wird Ihre Identit&auml;t verifiziert, bevor eine Auskunft erteilt werden kann. Dazu dient die Kennung, welche Sie innerhalb der Installation der App zugeteilt bekommen haben.&nbsp;</span></p>
      <p><span>Ebenso haben Sie das Recht auf Berichtigung, Sperrung oder L&ouml;schung Ihrer personenbezogenen Daten. Bitte wenden Sie sich dazu an das Team des Projekts BaAn-frei. Wenn die L&ouml;schung mit den gesetzlichen Aufbewahrungspflichten in Konflikt steht, werden die Daten entsprechend gesperrt. Sie k&ouml;nnen &Auml;nderungen oder den Widerruf einer Einwilligung durch entsprechende Mitteilung an uns mit Wirkung f&uuml;r die Zukunft vornehmen.</span></p>
      <h2><span>9. &Auml;nderung unserer Datenschutzerkl&auml;rung</span> </h2>

      <p><span>Sofern erforderlich, wird diese Datenschutzerkl&auml;rung w&auml;hrend des Verlaufs des Projekts angepasst. Dies dient einerseits dazu stets aktuelle, rechtliche Anforderungen einhalten zu k&ouml;nnen sowie Verbesserungen hinsichtlich des Konzepts und der technischen M&ouml;glichkeiten bez&uuml;glich des Datenschutzes vornehmen zu k&ouml;nnen. Gleiches gilt, falls neue Funktionen oder Leistungen in die App implementiert werden. F&uuml;r Ihre weitere Verwendung der App gilt dann die neue Datenschutzerkl&auml;rung, welche nach Aktualisierung stets erneut zu best&auml;tigen ist. Wird der &Auml;nderung widersprochen bzw. bleibt eine erneute Einwilligung aus, wird das Tracking der App inaktiv geschalten. Diese Funktion kann erst reaktiviert werden, sofern die neue Erkl&auml;rung akzeptiert worden ist.&nbsp;</span></p>
      <p><span>Die Rechte auf Auskunft, Berichtigung, Sperrung, L&ouml;schung und Widerspruch bleiben von einer &Auml;nderung unber&uuml;hrt.</span></p>
      <h2><span>10. Partner des Projekts</span>   </h2>

      <p><span>Neben der Hochschule Furtwangen sind die Hochschule Offenburg und die Outdooractive AG das in das Projekt eingebunden. Das BMDV ist Mittelgeber des Projekts und hat dementsprechend Vorgaben f&uuml;r die Ver&ouml;ffentlichung der Projektergebnisse erteilt. Mit Abschluss der Projektergebnisse werden Ver&ouml;ffentlichungen frei zug&auml;nglich im Open-Access-Format herausgegeben. Die Publikationen werden keine Rohdaten beinhalten und werden lediglich Kartierungen und Statistiken in f&uuml;r die Gesamtheit aller Teilnehmenden aggregierter Form beinhalten. Daraus folgt, dass stets das Mobilit&auml;tsverhalten mehrerer hundert Personen gleichzeitig untersucht wird und somit keine R&uuml;ckschl&uuml;sse auf einzelne Personen m&ouml;glich sind.&nbsp;</span></p>
      <p><span>Es wird darauf hingewiesen, dass die Partner in der Regel eigene Datenschutzbestimmungen haben, die au&szlig;erhalb des Einflusses der Hochschule bzw. der Projektarbeiten stehen. Jegliche Haftung und Verantwortung f&uuml;r die Datenschutzbestimmungen der Partner ist somit ausgeschlossen.</span></p>
      <h2><span >11. Ansprechpartner f&uuml;r den Datenschutz</span>   </h2>

      <p><span>Wenn Sie Fragen hinsichtlich der Verarbeitung der personenbezogenen Daten in der App haben, k&ouml;nnen Sie sich sowohl an das Projektteam als auch den Datenschutzbeauftragten der Hochschule wenden. Beide Anlaufstellen stehen f&uuml;r Auskunftsersuche, Anregungen oder Beschwerden zur Verf&uuml;gung.</span></p>
      <p><span>Wenn Sie Fragen hinsichtlich der Verarbeitung der personenbezogenen Daten in der App haben, k&ouml;nnen Sie sich sowohl an das Projektteam als auch den Datenschutzbeauftragten der Hochschule wenden. Beide Anlaufstellen stehen f&uuml;r Auskunftsersuche, Anregungen oder Beschwerden zur Verf&uuml;gung.</span></p>
      <p ><span>Team des Forschungsprojekts BaAn-frei an der Hochschule Furtwangen&nbsp;Robert-Gerwig-Platz 1&nbsp;78120 Furtwangen im Schwarzwald&nbsp;07723 920-2129&nbsp;NICOLAS.MAHN(AT)HFU.DE</span></p>
      <p ><span>&nbsp;</span></p>
      <p ><span>&nbsp;</span></p>

    </div>
  );
}

export default PrivacyPolicy;
