import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import authenticationReducer from './reducers/authentication.reducer';
import snackbarReducer from './reducers/snackbar.reducer';

const store = configureStore({
    reducer: {
        authentication: authenticationReducer,
        snackbar: snackbarReducer,
    },
    middleware: [thunk]
});

export default store;