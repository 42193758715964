import { RootStateInterface } from '../../../store/state-interfaces/root.state-interface';
import { SnackbarStateInterface } from '../../../store/state-interfaces/snackbar.state-interface';
import * as snackbarActions from '../../../store/actions/snackbar.actions';
import { connect } from 'react-redux';
import SnackbarItem from './SnackbarItem/SnackbarItem';

interface SnackbarPropsInterface {
    snackbar: SnackbarStateInterface;
    resetMessages: () => void;
}

function SnackbarComponent(props: SnackbarPropsInterface) {
    const { resetMessages, snackbar } = props;

    return (
        <>
            {snackbar.successMessage && <SnackbarItem message={snackbar.successMessage} severity="success" open={true} resetMessages={resetMessages} />}
            {snackbar.errorMessage && <SnackbarItem message={snackbar.errorMessage} severity="error" open={true} resetMessages={resetMessages} />}
        </>
    );
}

const mapStateToProps = (state: RootStateInterface) => {
    return {
        snackbar: state.snackbar
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        resetMessages: () => dispatch(snackbarActions.resetMessages())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SnackbarComponent);