import { Card, CardContent, CardHeader, Typography, Box, IconButton } from '@mui/material';
import { Clear, Place } from '@mui/icons-material';
import { LocationResponsibilityInterface } from '../../../../service/location-responsibility/location-responsibility.interface';
import './NotificationDataCard.css';

interface NotificationDataCardProps {
    data: LocationResponsibilityInterface[];
    deleteData: (id: number) => void;
}

function NotificationDataCard(props: NotificationDataCardProps) {
    const { data, deleteData } = props;

    return (
        <Card className="data-card">
            <CardContent className="card-content">
                <CardHeader className="card-header" title={
                    <Box className="header-box" display="flex" justifyContent="space-between" alignItems="center">
                        <div className="header-box-left">
                            <Typography variant="h5" align="left" gutterBottom fontWeight={700} color="primary" className="content-title">
                                Aktive Benachrichtigungen
                            </Typography>
                        </div>
                    </Box>
                } />
                <div className="notification-list">

                    {data.map((item, index) => (
                        <Card key={index} className="notification-card">
                            <CardContent className="card-content">
                                <div className="location-container">
                                    <Place className="icon" />
                                    <Typography variant="body1" align="left" gutterBottom className="content-text">
                                        {item.location}, {item.postcode}
                                    </Typography>
                                </div>
                                <IconButton className="button" onClick={() => deleteData(item.id)}>
                                    <Clear />
                                </IconButton>
                            </CardContent>
                        </Card>
                    ))}

                </div>
            </CardContent>
        </Card>
    )
}

export default NotificationDataCard;