import { Popup } from 'react-leaflet';
import { SimplifiedGeoresultCollectionInterface, PropertyTypeInterface } from '../../../../service/georesult/simplified-georesult.interface';
import RoutingPopupItem from './RoutingPopupItem/RoutingPopupItem';
import './RoutingPopup.css';

interface RoutingPopupProps {
    georesultCollection: SimplifiedGeoresultCollectionInterface;
    classDataLabel: PropertyTypeInterface[];
    categoryDataLabel: PropertyTypeInterface[];
}

function RoutingPopup(props: RoutingPopupProps) {
    const { georesultCollection, classDataLabel, categoryDataLabel } = props;
    const { video_file_name, category, date } = georesultCollection;
    const classProperty = georesultCollection.class;

    return (
        <Popup className="popup">
            <div className="popup-content">
                <RoutingPopupItem title="Video Datei" subtitle={video_file_name} />
                <RoutingPopupItem title="Erstellt am" subtitle={date} />
                <RoutingPopupItem title="Klasse" tableData={classProperty} dataLabel={classDataLabel} />
                <RoutingPopupItem title="Kategorie" tableData={category} dataLabel={categoryDataLabel} />
            </div>
        </Popup>
    )
}

export default RoutingPopup;