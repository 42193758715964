import { IconButton, TableRow, TableCell, TextField, Button } from '@mui/material';
import { EditOutlined, DeleteOutlined, VisibilityOutlined } from '@mui/icons-material';
import { dateHelper } from '../../../../utils/helper';
import DetailedTableItem from '../DetailedTableItem/DetailedTableItem';
import useEditContainerController from '../../../../hooks/useEditContainer.controller';
import './TableItem.css';

interface TableItemProps {
    item: any;
    onDelete?: (id: number) => void;
    onUpdate?: (item: any) => void;
}

function TableItem(props: TableItemProps) {
    const { item, onUpdate, onDelete } = props;
    const {
        edit,
        editItem,
        open,
        openEditHandler,
        closeEditHandler,
        updateItemHandler,
        openDialogHandler,
        closeDialogHandler,
        changeItemHandler,
        changeNestedItemHandler
    } = useEditContainerController({ item, onUpdate });

    return (
        <>
            <TableRow className="table-row">

                {Object.keys(item).map((key: string, index: number) => (
                    <TableCell className="table-cell" key={index}>

                        {typeof item[key] === 'object' && item[key] !== null ? (
                            edit ? (
                                <TextField
                                    className="dialog-textfield"
                                    variant="filled"
                                    fullWidth
                                    type="number"
                                    value={editItem[key].id}
                                    classes={{ root: "textfield-root" }}
                                    InputProps={{ disableUnderline: true }}
                                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]' }}
                                    onChange={(event) => changeNestedItemHandler(event, key)}
                                />
                            ) : (
                                item[key].name
                            )
                        ) : key === 'createdAt' || key === 'updatedAt' ? (
                            dateHelper(item[key])
                        ) : (
                            edit && key !== 'id' ? (
                                <TextField
                                    className="dialog-textfield"
                                    variant="filled"
                                    fullWidth
                                    type="text"
                                    value={editItem[key]}
                                    classes={{ root: "textfield-root" }}
                                    InputProps={{ disableUnderline: true }}
                                    onChange={(event) => changeItemHandler(event, key)}
                                />
                            ) : (
                                item[key]
                            )
                        )}

                    </TableCell>
                ))}

                {(onUpdate !== undefined || onDelete !== undefined) && (
                    <TableCell className={`table-cell actions ${edit ? 'edit' : ''}`}>

                        {edit && onUpdate !== undefined ? (
                            <>
                                <Button
                                    className="dialog-button save"
                                    autoFocus
                                    variant="contained"
                                    onClick={updateItemHandler}
                                >
                                    Speichern
                                </Button>
                                <Button
                                    className="dialog-button cancel"
                                    autoFocus
                                    variant="outlined"
                                    onClick={closeEditHandler}
                                >
                                    Abbrechen
                                </Button>
                            </>
                        ) : (
                            <>
                                {onUpdate !== undefined && (
                                    <IconButton className="table-button view">
                                        <VisibilityOutlined onClick={openDialogHandler} />
                                    </IconButton>
                                )}

                                {onUpdate !== undefined && (
                                    <IconButton className="table-button edit">
                                        <EditOutlined onClick={openEditHandler} />
                                    </IconButton>
                                )}

                                {onDelete !== undefined && (
                                    <IconButton className="table-button delete">
                                        <DeleteOutlined onClick={() => onDelete(item.id)} />
                                    </IconButton>
                                )}
                            </>
                        )}

                    </TableCell>
                )}

            </TableRow>

            <DetailedTableItem item={item} isOpen={open} onClose={closeDialogHandler} onUpdate={onUpdate} />
        </>
    )
}

export default TableItem;