import ABPALogo from '../../../assets/logos/ABPA_logo.jpg';
import { Avatar, Typography } from '@mui/material';
import './SidebarHeader.css';

function SidebarHeader() {
    return (
        <div className="sidebar-header">
            <Avatar className="avatar">
                <img src={ABPALogo} alt="ABPA-Logo" />
            </Avatar>
            <div className="header-title">
                <Typography component="h1" variant="h1">
                    ABPA
                </Typography>
                <Typography component="h2" variant="h2">
                    Automatic Bike Path <br/> Analysis
                </Typography>
            </div>
        </div>
    )
}

export default SidebarHeader;