import { ServerLogServiceInterface } from './server-log-service.interface';
import { Logger } from '../../utils/logger';
import axios from 'axios';
import { API_BASE_URL } from '../../common/constants';

export class ServerLogService implements ServerLogServiceInterface {
    constructor() {
        this.logger = new Logger('ServerLogService');
        this.logger_base_url = `${API_BASE_URL}/logs`;
    }

    readonly logger_base_url: string;
    readonly logger: Logger;

    async getAllLogs(
        token: string,
        successCallback: (response: string[]) => void,
        errorCallback: (error: any) => void
    ): Promise<void> {
        await axios.get<string[]>(`${this.logger_base_url}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then((response) => {
                this.logger.log(`Logs fetched successfully.`);
                successCallback(response.data);
            })
            .catch((error) => {
                this.logger.error(error);
                errorCallback(error);
            });
    }
}