import { Typography } from '@mui/material';
import Title from '../Title/Title';
import './AuthInformation.css';

function AuthInformation() {
  return (
    <div className="login-information">
      <Title />
      <Typography variant="subtitle1" align="left" color="white">
        Melden Sie sich an, um fortzufahren
      </Typography>
    </div>
  )
}

export default AuthInformation;