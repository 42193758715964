import { OrganisationService } from '../../../../service/organisation/organisation.service';
import DataContainer from '../../../container/DataContainer/DataContainer';
import { EditOrganisationInterface } from '../../../../service/organisation/organisation.interface';

function Organisations() {
  return (
    <DataContainer
      resourceService={new OrganisationService()}
      title="Organisations"
      deleteAllowed={true}
      updateAllowed={true}
    />
  )
}

export default Organisations;