import { useRef, useMemo } from 'react';
import { Polyline, PolylineProps } from 'react-leaflet';
import { POLYLINE_INITIAL_WEIGHT, POLYLINE_HOVER_WEIGHT, POLYLINE_HOVER_COLOR, POLYLINE_INITIAL_OPACITY } from '../../../common/constants';
import { RecordInterface } from '../../../service/record/record.interface';
import { LatLngBounds, LatLngBoundsExpression } from 'leaflet';

interface RoutingLineProps {
    recordData: RecordInterface;

}

function RoutingLine(props: RoutingLineProps) {
    const {recordData} = props;
    const polylineRef = useRef<any | null>(null);
    const coordinates: [number, number][] = recordData.recordLocation.map(location => [parseFloat(location.latitude), parseFloat(location.longitude)]);

    return (
        <Polyline
            ref={polylineRef}
            positions={coordinates}
            color={POLYLINE_HOVER_COLOR}
            weight={POLYLINE_INITIAL_WEIGHT}
            opacity={POLYLINE_INITIAL_OPACITY}
            lineCap="square"
            lineJoin="round"
        >
        </Polyline>
    );
}

export default RoutingLine;