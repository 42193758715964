import * as actionType from '../action-types/snackbar.action-types';

const onDispatch = (type: string, payload: any) => ({ type, payload });

export const setSuccessMessage = (message: string) => {
    return (dispatch: any) => {
        dispatch(onDispatch(actionType.SET_SUCCESS_MESSAGE, message));
    }
}

export const setErrorMessage = (message: string) => {
    return (dispatch: any) => {
        dispatch(onDispatch(actionType.SET_ERROR_MESSAGE, message));
    }
}

export const resetMessages = () => {
    return (dispatch: any) => {
        dispatch(onDispatch(actionType.RESET_MESSAGES, null));
    }
}
