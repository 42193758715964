import { RootResourceService } from '../root/root-resource-service';
import { RecordInterface } from './record.interface';
import axios from 'axios';

export class RecordService extends RootResourceService<RecordInterface> {
    constructor() {
        super('record');
    }

    async getByApplication(token: string, application: string): Promise<RecordInterface[]> {
        try {
            const response = await axios.get<RecordInterface[]>(`${this.resource_url}/application/${application}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            this.logger.log(`${this.resource_url} fetched successfully.`);

            return response.data;
        } catch (error) {
            this.logger.error(error);

            throw error;
        }
    }
}