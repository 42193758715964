import { Card, CardContent, Typography } from '@mui/material';
import DocumentTitleContainer from '../../container/DocumentTitleContainer/DocumentTitleContainer';
import { API_INSTRUCTIONS } from '../../../assets/documentation/api-instructions/api-instructions';
import InstructionItem from './InstructionItem/InstructionItem';
import SmallCardContainer from '../../container/SmallCardContainer/SmallCardContainer';
import './Instructions.css';

function Instructions() {
  const handleScrollToInstructions = (index: number) => {
    const instructionItem = document.getElementById(`instruction-item-${index}`);

    if (instructionItem) {
      instructionItem.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <DocumentTitleContainer title="Anleitung">
      <SmallCardContainer className="instructions-container">

        <div className="instructions">

          {API_INSTRUCTIONS.map((instruction, index) => {

            return (
              <InstructionItem
                key={index}
                id={index}
                apiInstruction={instruction}
              />
            )
          })}

        </div>
        <div className="table-of-contents">
          <ul className="fixed-container">
            <li className="toc-item">
              <Typography variant="h6" component="h6" className="toc-header">
                Schnellnavigation
              </Typography>
            </li>
            {API_INSTRUCTIONS.map((instruction, index) => {
              return (
                <li key={index} className="toc-item">
                  <Typography
                    variant="body1"
                    component="p"
                    className="toc-item-text"
                    onClick={() => handleScrollToInstructions(index)}
                  >
                    {instruction.headline}
                  </Typography>
                </li>
              )
            })
            }

          </ul>
        </div>
      </SmallCardContainer>
    </DocumentTitleContainer>
  );
}

export default Instructions;