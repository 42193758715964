import React from 'react';
import { Card, CardContent, CardHeader } from '@mui/material';
import './SmallCardContainer.css';

interface SmallCardContainerProps {
    className?: string;
    title?: React.ReactNode;
    children: React.ReactNode;
}

function SmallCardContainer(props: SmallCardContainerProps) {
    const { title, children } = props;

    return (
        <div className={`small-card-container ${props.className}`} >
            <Card className="small-card">
                <CardContent className="card-content">
                    <CardHeader className="card-header" title={title} />
                    {children}
                </CardContent>
            </Card>
        </div>
    )
}

export default SmallCardContainer;