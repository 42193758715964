import { BiketypeService } from '../../../../service/biketype/biketype.service';
import DataContainer from '../../../container/DataContainer/DataContainer';
import { EditBiketypeInterface } from '../../../../service/biketype/biketype.interface';

function Biketypes() {
    const editBiketypeObject: EditBiketypeInterface = {
        name: ""
    };

    return (
        <DataContainer
            resourceService={new BiketypeService()}
            editResourceObject={editBiketypeObject}
            title={"Biketypes"}
            deleteAllowed={true}
            updateAllowed={true}
        />
    )
}

export default Biketypes;