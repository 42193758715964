import React, { useState } from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Typography, List } from '@mui/material';
import { ExpandMoreOutlined } from '@mui/icons-material';
import { ApiDocumentationItemInterface } from '../../../../assets/documentation/api-documentation/api-documentation.interface';
import ItemRequirement from './ItemRequirements/ItemRequirement';
import './DocumentationItem.css'

interface DocumentationItemProps {
    requirementTitles: string[];
    item: ApiDocumentationItemInterface;
}

function DocumentationItem({ item, requirementTitles }: DocumentationItemProps) {
    const { method, path, description, params, header, body, response } = item;
    const [expanded, setExpanded] = useState<boolean>(false);

    return (
        <Accordion className={`accordion item${expanded ? " expanded" : ""}`} expanded={expanded} onChange={() => setExpanded(!expanded)} disableGutters elevation={0}>
            <AccordionSummary className="accordion-summary" expandIcon={<ExpandMoreOutlined className="expand-icon" />}>
                <div className="accordion-summary-title">
                    <Typography className="method-title" variant="body2">{method}</Typography>
                    <Typography className="path-title">{path}</Typography>
                </div>
            </AccordionSummary>
            <AccordionDetails className="accordion-details">
                <Typography className="description" variant="body2">{description}</Typography>
                <List className="requirements">

                    {[params, header, body, response].map((requirement, index) => {
                        return (
                            <ItemRequirement title={requirementTitles[index]} requirement={requirement} />
                        )
                    }
                    )}

                </List>
            </AccordionDetails>
        </Accordion>
    )
}
export default DocumentationItem