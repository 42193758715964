import { Avatar, Box, Card, CardContent, Collapse, IconButton, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, SelectChangeEvent, CircularProgress } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AndroidIcon from '@mui/icons-material/Android';
import NoAccountsIcon from '@mui/icons-material/NoAccounts';
import PencilOutlineIcon from '@mui/icons-material/CreateOutlined';
import AppleIcon from '@mui/icons-material/Apple';
import { MobileLogSummaryInterface } from '../../../service/mobile-log/mobile-log-summary.interface';
import { MobileLogStatus } from '../../../service/mobile-log/mobile-log-status.enum';
import { dateHelper } from '../../../utils/helper';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootStateInterface } from '../../../store/state-interfaces/root.state-interface';
import { MobilLogService } from '../../../service/mobile-log/mobile-log.service';
import './ReportCard.css';

interface ReportCardProps {
    mobileLogSummary: MobileLogSummaryInterface;
    getAllMobileLogSummaries: (feedback?: boolean) => Promise<void>;
}

function ReportCard(props: ReportCardProps) {
    const { mobileLogSummary, getAllMobileLogSummaries } = props;
    const [onEdit, setOnEdit] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [expanded, setExpanded] = useState(false);
    const authentication = useSelector((state: RootStateInterface) => state.authentication);
    const mobileLogService = new MobilLogService();

    const handleEdit = () => {
        setOnEdit(!onEdit);
    }

    const handleToggle = () => {
        setExpanded(!expanded);
    };

    const handleStatusChange = async (event: SelectChangeEvent<MobileLogStatus>, mobileLogSummary: MobileLogSummaryInterface) => {
        if (!authentication.authentication?.access_token) return;

        setLoading(true);

        try {
            const data: MobileLogSummaryInterface = { ...mobileLogSummary, status: event.target.value as MobileLogStatus };
            await mobileLogService.updateStatus(authentication.authentication?.access_token, data);
            await getAllMobileLogSummaries(false);

            setOnEdit(false);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    }

    return (
        <Card className={`report-card ${expanded ? 'expanded' : ''}`}>
            <CardContent className="card-content">
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}>
                    <Avatar className="avatar">
                        {mobileLogSummary.mobileUser.device_os === 'Android' ? (
                            <AndroidIcon className="icon" />
                        ) : mobileLogSummary.mobileUser.device_os === 'iOS' ? (
                            <AppleIcon className="icon" />
                        ) : (
                            <NoAccountsIcon className="icon" />
                        )}
                    </Avatar>
                    <div className="card-item">
                        <Typography className="label">Betriebssystem</Typography>
                        <Typography className="value">{`${mobileLogSummary.mobileUser.device_os} ${mobileLogSummary.mobileUser.device_os_version}`}</Typography>
                    </div>
                    <div className="card-item">
                        <Typography className="label">Log Anzahl</Typography>
                        <Typography className="value">{`${mobileLogSummary.mobileLogs.length} Logs`}</Typography>
                    </div>
                    <div className="card-item">
                        {onEdit ? (
                            <>
                                {
                                    loading === false ? (
                                        <Select
                                            value={mobileLogSummary.status}
                                            onChange={(event) => handleStatusChange(event, mobileLogSummary)}
                                            className="select"
                                            sx={{ minWidth: '100px' }}
                                        >
                                            {Object.values(MobileLogStatus).map((mobileLogStatus) => (
                                                <MenuItem key={mobileLogStatus} value={mobileLogStatus}>
                                                    <Typography className="value">{mobileLogStatus.charAt(0).toUpperCase() + mobileLogStatus.slice(1)}</Typography>
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    ) : (
                                        <CircularProgress />
                                    )}
                            </>
                        ) : (
                            <>
                                <Typography className="label">Status</Typography>
                                <Typography className="value">{mobileLogSummary.status}</Typography>
                            </>
                        )}
                    </div>
                    <div className="card-actions">
                        <IconButton onClick={handleEdit}>
                            <PencilOutlineIcon />
                        </IconButton>
                        <IconButton onClick={handleToggle}>
                            <ExpandMoreIcon
                                sx={{
                                    transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
                                    transition: 'transform 0.3s',
                                }}
                            />
                        </IconButton>
                    </div>
                </Box>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <div className="card-body">
                        <TableContainer>
                            <Table className="table" aria-label="simple table">
                                <TableHead className="table-head">
                                    <TableRow className="table-row">
                                        {Object.keys(mobileLogSummary.mobileLogs[0]).map((key) => (
                                            <TableCell key={key} className="table-cell">{key}</TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody className="table-body">
                                    {mobileLogSummary.mobileLogs.map((mobileLog) => (
                                        <TableRow key={mobileLog.id} className="table-row">
                                            <TableCell className="table-cell id">{mobileLog.id}</TableCell>
                                            <TableCell className="table-cell message">{mobileLog.message}</TableCell>
                                            <TableCell className="table-cell section">{mobileLog.section}</TableCell>
                                            <TableCell className={`table-cell status ${mobileLog.type}`}>{mobileLog.type}</TableCell>
                                            <TableCell className="table-cell createdAt">{dateHelper(mobileLog.createdAt.toString())}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </Collapse>
            </CardContent>
        </Card>
    )
}

export default ReportCard;