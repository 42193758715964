import { IconButton } from '@mui/material';
import { MenuOutlined, MenuOpenOutlined } from '@mui/icons-material';
import Profile from '../Profile/Profile';
import './Header.css';

interface HeaderProps {
    isDrawerOpen: boolean;
    toggleDrawer: () => void;
}

function Header({ isDrawerOpen, toggleDrawer }: HeaderProps) {
    return (
        <div className="header">
            <IconButton aria-label="menu" onClick={toggleDrawer}>
                {isDrawerOpen ? <MenuOutlined /> : <MenuOpenOutlined />}
            </IconButton>
            <Profile />
        </div>
    )
}

export default Header;