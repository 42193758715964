import { RootResourceService } from '../root/root-resource-service';
import { EditOrganisationInterface, OrganisationInterface } from './organisation.interface';
import axios from 'axios';

export class OrganisationService extends RootResourceService<OrganisationInterface> {
    constructor() {
        super('organisation');
    }

    async update(token: string, data: OrganisationInterface): Promise<any> {
        try {
            const editOrganisation: EditOrganisationInterface = { ...data };

            const response = await axios.put<string>(`${this.resource_url}/${data.id}`, editOrganisation, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            this.logger.log(`${this.resource_url} updated successfully.`);

            return response.data;
        } catch (error) {
            this.logger.error(error);

            throw error;
        }
    }
}
