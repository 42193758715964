export const API_BASE_URL: string = "https://abpa-cloud.de/api";    //online: https://abpa-cloud.de/api offline: http://localhost:8080/api
export const ACCESS_TOKEN_NAME: string = "abpa_token";
export const INFO_WEBSITE_URL: string = "https://www.hs-furtwangen.de/was-uns-bewegt/mobilitaet/forschungsprojekt-abpa/";
export const EMAIL_REGEX: RegExp = /^[a-zA-Z0-9]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/;
export const PASSWORD_REGEX: RegExp = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^\w\d\s]).{8,}$/; // mindestens 8 Zeichen, mindestens ein Großbuchstabe, ein Kleinbuchstabe, eine Zahl und ein Sonderzeichen
export const SNACKBAR_HIDE_DURATION: number = 5000;
export const SKELETON_ROWS: number = 5;
export const SKELETON_COLUMNS: number = 6;
export const SKELETON_WIDTH: number = 100;
export const INITIAL_COORDINATES: [number, number] = [48.00, 7.83];
export const INITIAL_ZOOM: number = 13;
export const POLYLINE_INITIAL_OPACITY: number = 0.9;
export const POLYLINE_INITIAL_WEIGHT: number = 2;
export const POLYLINE_HOVER_WEIGHT: number = 5;
export const POLYLINE_HOVER_COLOR: string = "#000000";
