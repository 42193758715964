import { useEffect, useRef } from 'react';
import { Box, Card, CardContent, CardHeader, IconButton, List, ListItem, Typography } from '@mui/material';
import { CachedOutlined, ArrowUpwardOutlined } from '@mui/icons-material';
import { AuthenticationStateInterface } from '../../../store/state-interfaces/authentication.state-interface';
import { RootStateInterface } from '../../../store/state-interfaces/root.state-interface';
import { connect } from 'react-redux';
import * as snackbarActions from '../../../store/actions/snackbar.actions';
import useServerLogHandler from '../../../hooks/useServerLog.handler';
import DocumentTitleContainer from '../../container/DocumentTitleContainer/DocumentTitleContainer';
import SmallCardContainer from '../../container/SmallCardContainer/SmallCardContainer';
import './Logs.css';

interface LogsInterface {
    authentication: AuthenticationStateInterface;
    setSuccessMessage: (payload: string) => void;
    setErrorMessage: (payload: string) => void;
}

function Logs(props: LogsInterface) {
    const { authentication, setSuccessMessage, setErrorMessage } = props;
    const { loading, serverLogs, getServerLogs } = useServerLogHandler({ authentication });
    const ListItemRef = useRef<HTMLUListElement>(null)

    useEffect(() => {
        getServerLogs();
    }, []);

    useEffect(() => {
        scrollToBottom();
    }, [serverLogs]);

    const scrollToBottom = () => {
        if (ListItemRef.current) {
            ListItemRef.current.scrollTop = ListItemRef.current.scrollHeight;
        }
    }

    const scrollToTop = () => {
        if (ListItemRef.current) {
            ListItemRef.current.scrollTop = 0;
        }
    }

    return (
        <DocumentTitleContainer title="Server Logs">
            <SmallCardContainer
                className="logs-container"
                title={
                    <Box className="header-box" display="flex" justifyContent="space-between" alignItems="center">
                        <div className="header-box-left">
                            <Typography variant="h4" align="left" gutterBottom fontWeight={700} color="primary" className="content-title">
                                Server Logs
                            </Typography>
                        </div>
                        <div className="header-box-right">
                            <IconButton className="icon-button" onClick={scrollToTop}>
                                <ArrowUpwardOutlined sx={{ fontSize: 45 }} />
                            </IconButton>
                            <IconButton className="icon-button" onClick={getServerLogs}>
                                <CachedOutlined sx={{ fontSize: 45 }} />
                            </IconButton>
                        </div>
                    </Box>
                }>
                <div className="outer-log-list">
                    <List className="log-list" ref={ListItemRef}>
                        {serverLogs.map((log: string, index: number) => (
                            <ListItem key={index} className="log-list-item">
                                <Typography variant="body2" align="left" gutterBottom className="log-list-item-text">
                                    {log}
                                </Typography>
                            </ListItem>
                        ))}
                    </List>
                </div>
            </SmallCardContainer>
        </DocumentTitleContainer>
    )
}

const mapStateToProps = (state: RootStateInterface) => {
    return {
        authentication: state.authentication
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        setSuccessMessage: (payload: string) => dispatch(snackbarActions.setSuccessMessage(payload)),
        setErrorMessage: (payload: string) => dispatch(snackbarActions.setErrorMessage(payload))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Logs);