export const dateHelper = (dateString: string) => {
    const dateObj = new Date(dateString);

    const year = dateObj.getFullYear();
    const month = dateObj.getMonth() + 1;
    const day = dateObj.getDate();
    const hours = dateObj.getHours();
    const minutes = dateObj.getMinutes();

    return `${hours}:${minutes} - ${day}/${month}/${year}`;
};