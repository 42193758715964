import { AuthenticationInterface, AuthenticationPayloadInterface } from './authentication.interface';
import { AuthenticationServiceInterface } from './authentication-service.interface';
import { ACCESS_TOKEN_NAME } from '../../common/constants';
import axios from 'axios';
import { Logger } from '../../utils/logger';
import { API_BASE_URL } from '../../common/constants';
import { ApplicationName } from '../mobile-user/application-name.enum';
import Header from '../../components/ui/Header/Header';

export class AuthenticationService implements AuthenticationServiceInterface {
    constructor() {
        this.logger = new Logger('AuthenticationService');
        this.authentication_base_url = `${API_BASE_URL}/authentication`;
    }

    readonly authentication_base_url: string;
    readonly logger: Logger;

    async loginUser(
        authenticationData: AuthenticationInterface,
        successCallback: (response: AuthenticationPayloadInterface) => void,
        errorCallback: (error: any) => void
    ): Promise<void> {
        await axios.post<AuthenticationPayloadInterface>(`${this.authentication_base_url}`, authenticationData)
            .then((response) => {
                localStorage.setItem(ACCESS_TOKEN_NAME, response.data.access_token);

                this.logger.log(`User ${response.data.username} logged in successfully.`);
                successCallback(response.data);
            })
            .catch((error) => {
                this.logger.error(error);
                errorCallback(error);
            });
    }

    async logoutUser(): Promise<void> {
        localStorage.removeItem(ACCESS_TOKEN_NAME);

        this.logger.log(`User logged out successfully.`);
    }

    async generateApplicationToken(token: string, applicationName: ApplicationName): Promise<AuthenticationPayloadInterface> {
        try {
            const response = await axios.get(`${this.authentication_base_url}/access/application/${applicationName}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            this.logger.log(`Application token generated successfully.`);

            return response.data;
        } catch (error) {
            this.logger.error(error);

            throw error;
        }
    }

    async validateToken(
        successCallback: (response: AuthenticationPayloadInterface) => void,
        errorCallback: (error: any) => void
    ): Promise<void> {
        const accessToken: string | null = localStorage.getItem(ACCESS_TOKEN_NAME);

        if (!accessToken) {
            this.logger.error(`Token not found.`);
            errorCallback(`Token not found.`);
            return;
        }

        await axios.get<AuthenticationPayloadInterface>(`${this.authentication_base_url}/validate`, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        })
            .then((response) => {
                this.logger.log(`Token validated successfully.`);
                successCallback(response.data);
            })
            .catch((error) => {
                this.logger.error(error);
                errorCallback(error);
            });
    }
}

