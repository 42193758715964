import React, { useEffect } from 'react';
import DocumentTitleContainer from '../../container/DocumentTitleContainer/DocumentTitleContainer';
import { Card, CardContent, CardHeader, Typography, Box, IconButton, List, ListItem } from '@mui/material';
import { FullscreenOutlined, Download } from '@mui/icons-material';
import useAnalysationDataController from '../../../hooks/useAnalysationData.controller';
import RoutingLine from './RoutingLine/RoutingLine';
import FullScreenDialog from '../../ui/FullScreenDialog/FullScreenDialog';
import RoutingMapContainer from '../../container/RoutingMapContainer/RoutingMapContainer';
import VideoPopup from './VideoPopup/VideoPopup';
import 'leaflet/dist/leaflet.css';
import './AnalysationData.css';

function AnalysationData() {
  const { data, classDataLabel, categoryDataLabel, loading, error, getSimplifiedGeoresults, selectData, unselectData, selectedData, downloadData, downloadRawData } = useAnalysationDataController();
  const [openDialog, setOpenDialog] = React.useState(false);

  const openDialogHandler = () => {
    setOpenDialog(true);
  }

  useEffect(() => {
    (async () => {
      await getSimplifiedGeoresults();
    })();
  }, []);

  const mapContainer = (
    <RoutingMapContainer>

      {data && classDataLabel && categoryDataLabel && data.map((feature, index) => (
        <RoutingLine georesultCollection={feature} key={index} selectData={selectData} categoryDataLabel={categoryDataLabel} classDataLabel={classDataLabel} />
      ))}

    </RoutingMapContainer>
  )

  return (
    <DocumentTitleContainer title="Analysedaten">
      <div className="data-container analysation-data">
        <Card className="data-card">
          <CardContent className="card-content">
            <CardHeader className="card-header" title={
              <Box className="header-box" display="flex" justifyContent="space-between" alignItems="center">
                <div className="header-box-left">
                  <Typography variant="h4" align="left" gutterBottom fontWeight={700} color="primary" className="content-title">
                    Analysedaten
                  </Typography>
                </div>
                <div className="header-box-right">
                  <IconButton className="icon-button download" onClick={downloadRawData}>
                    <Typography variant="body1" className="text">herunterladen</Typography><Download className="icon" />
                  </IconButton>
                  <IconButton className="icon-button download" onClick={downloadData}>
                    <Typography variant="body1" className="text">bereinigte Daten herunterladen</Typography><Download className="icon" />
                  </IconButton>
                  <IconButton className="icon-button" onClick={openDialogHandler}>
                    <FullscreenOutlined sx={{ fontSize: 45 }} />
                  </IconButton>
                </div>
              </Box>
            } />
            <Box className="content-box" display="flex" justifyContent="center" alignItems="center" gap={4}>
              <div className="content-container">
                {openDialog === false && mapContainer}
              </div>

              {classDataLabel && (
                <div className="map-legend-container minimized">
                  <List className="map-legend-list">

                    {classDataLabel.map((classLabel, index) => (
                      <ListItem className="list-item" key={index}>
                        <div className="map-legend-color" style={{ backgroundColor: classLabel.color }}></div>
                        <Typography variant="body1" className="map-legend-description">{classLabel.name}</Typography>
                      </ListItem>
                    ))}

                  </List>
                </div>
              )}

            </Box>
          </CardContent>
        </Card>

        <FullScreenDialog openDialog={openDialog} closeDialogHandler={() => setOpenDialog(false)} title="Analysedaten">
          {openDialog === true && mapContainer}

          {classDataLabel && (
            <div className="map-legend-container fullscreen">
              <Card className="map-legend">
                <List className="map-legend-list">

                  {classDataLabel.map((classLabel, index) => (
                    <ListItem className="list-item" key={index}>
                      <div className="map-legend-color" style={{ backgroundColor: classLabel.color }}></div>
                      <Typography variant="body1" className="map-legend-description">{classLabel.name}</Typography>
                    </ListItem>
                  ))}

                </List>
              </Card>
            </div>
          )}

        </FullScreenDialog>

        {selectedData && classDataLabel && categoryDataLabel && <VideoPopup open={selectedData !== null} selectedData={selectedData} classDataLabel={classDataLabel} categoryDataLabel={categoryDataLabel} onClose={unselectData} />}

      </div>
    </DocumentTitleContainer>
  );
}

export default AnalysationData;
