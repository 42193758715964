import { useState } from 'react';
import { OutlinedInput, InputAdornment, IconButton } from '@mui/material';
import { VisibilityOutlined, VisibilityOffOutlined, ContentCopyOutlined } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import * as snackbarActions from '../../../store/actions/snackbar.actions';

interface TokenTextFieldProps {
    token: string | null;
}

function TokenTextField(props: TokenTextFieldProps) {
    const { token } = props;
    const [showPassword, setShowPassword] = useState(false);
    const dispatch = useDispatch();

    const handleCopy = () => {
        if (token === null) return;

        navigator.clipboard.writeText(token);
        dispatch(snackbarActions.setSuccessMessage('API Key kopiert') as any);
    }

    return (
        <OutlinedInput
            type={showPassword ? 'text' : 'password'}
            value={token}
            inputProps={{ readOnly: true }}
            size="small"
            sx={{ maxWidth: "1000px" }}
            className="api-key-input"
            endAdornment={
                <InputAdornment position="end">
                    <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                    >
                        {showPassword ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
                    </IconButton>
                    <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleCopy}
                    >
                        <ContentCopyOutlined />
                    </IconButton>
                </InputAdornment>
            }
        />
    )
}

export default TokenTextField;