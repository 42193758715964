import { RootResourceService } from '../root/root-resource-service';
import { EditMobileUserInterface, MobileUserInterface } from './mobile-user.interface';
import axios from 'axios';

export class MobileUserService extends RootResourceService<MobileUserInterface> {
    constructor() {
        super('mobileuser');
    }

    async update(token: string, data: MobileUserInterface): Promise<any> {
        try {
            const editMobileUser: EditMobileUserInterface = {
                ...data,
                OrganisationId: data.organisation.id
            };

            const response = await axios.put<string>(`${this.resource_url}/${data.id}`, editMobileUser, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            this.logger.log(`${this.resource_url} updated successfully.`);

            return response.data;
        } catch (error) {
            this.logger.error(error);

            throw error;
        }
    }
}
