import React from 'react';

interface DocumentTitleContainerProps {
    title: string;
    children: React.ReactNode;
}

class DocumentTitleContainer extends React.Component<DocumentTitleContainerProps> {
    componentDidMount() {
        document.title = this.props.title;
    }

    componentDidUpdate() {
        document.title = this.props.title;
    }

    render() {
        return this.props.children;
    }
}

export default DocumentTitleContainer;