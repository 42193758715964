import { RootResourceService } from '../root/root-resource-service';
import { MobileConfigurationInterface, EditMobileConfigurationInterface } from './mobile-configuration.interface';
import axios from 'axios';

export class MobileConfigurationService extends RootResourceService<MobileConfigurationInterface> {
    constructor() {
        super('mobile-configuration');
    }

    async update(token: string, data: MobileConfigurationInterface): Promise<any> {
        try {
            const editMobileConfiguration: EditMobileConfigurationInterface = { ...data };

            const response = await axios.put<string>(`${this.resource_url}/${data.id}`, editMobileConfiguration, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            this.logger.log(`${this.resource_url} updated successfully.`);

            return response.data;
        } catch (error) {
            this.logger.error(error);

            throw error;
        }
    }
}
