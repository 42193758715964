import { useState } from 'react';
import { AuthenticationInterface } from '../service/authentication/authentication.interface';
import { PASSWORD_REGEX, EMAIL_REGEX } from '../common/constants';

function useAuthenticationController() {
    const [userInformation, setUserInformation] = useState<AuthenticationInterface>({
        username: '',
        password: '',
    });
    const [emailError, setEmailError] = useState<string>('');
    const [passwordError, setPasswordError] = useState<string>('');

    const validateEmail = () => {
        if (userInformation.username.includes('admin')) {
            setEmailError('');
            return true;
        }

        if (!EMAIL_REGEX.test(userInformation.username)) {
            setEmailError('Please enter a valid email address');
            return false;
        }

        setEmailError('');
        return true;
    };

    const validatePassword = () => {
        if (userInformation.password.length < 2) {
            setPasswordError('Password must be at least 2 characters long');
            return false;
        }

        setPasswordError('');
        return true;
    };

    return {
        userInformation,
        setUserInformation,
        emailError,
        passwordError,
        validateEmail,
        validatePassword,
    }
}

export default useAuthenticationController;