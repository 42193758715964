import * as actionType from '../action-types/authentication.action-types';
import { AuthenticationStateInterface } from '../state-interfaces/authentication.state-interface';

const initialState: AuthenticationStateInterface = {
    authentication: null,
    tokenValidation: {
        loading: false,
        error: '',
    },
    login: {
        loading: false,
        error: '',
    },
};

const authenticationReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case actionType.LOGIN_USER:
            return {
                ...state,
                authentication: null,
                login: {
                    loading: true,
                    error: '',
                },
            };
        case actionType.LOGIN_USER_SUCCESS:
            return {
                ...state,
                authentication: action.payload,
                login: {
                    loading: false,
                    error: '',
                },
            };
        case actionType.LOGIN_USER_ERROR:
            return {
                ...state,
                authentication: null,
                login: {
                    loading: false,
                    error: action.payload,
                },
            };
        case actionType.LOGOUT_USER:
            return {
                ...state,
                authentication: null,
            };
        case actionType.VALIDATE_TOKEN:
            return {
                ...state,
                authentication: null,
                tokenValidation: {
                    loading: true,
                    error: '',
                },
            };
        case actionType.VALIDATE_TOKEN_SUCCESS:
            return {
                ...state,
                authentication: action.payload,
                tokenValidation: {
                    loading: false,
                    error: '',
                },
            };
        case actionType.VALIDATE_TOKEN_ERROR:
            return {
                ...state,
                authentication: null,
                tokenValidation: {
                    loading: false,
                    error: action.payload,
                },
            };
        default:
            return state;
    }
};

export default authenticationReducer;