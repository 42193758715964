export class Logger {
    constructor(section: string) {
        this.section = section;
    }

    readonly section: string;

    public log(message: any): void {
        console.log(`[${this.section}] ${message}`);
    }

    public error(message: any): void {
        console.error(`[${this.section}] ${message}`);
    }
}