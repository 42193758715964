import { Card, Dialog, IconButton, Slide, CardContent, Typography, Table, TableCell, TableRow } from '@mui/material';
import { Close } from '@mui/icons-material';
import { PropertyTypeInterface, SimplifiedGeoresultCollectionInterface } from '../../../../service/georesult/simplified-georesult.interface';
import { API_BASE_URL } from '../../../../common/constants';
import { RootStateInterface } from '../../../../store/state-interfaces/root.state-interface';
import { useSelector } from 'react-redux';
import './VideoPopup.css';

interface VideoPopupProps {
    open: boolean;
    selectedData: SimplifiedGeoresultCollectionInterface;
    classDataLabel: PropertyTypeInterface[];
    categoryDataLabel: PropertyTypeInterface[];
    onClose: () => void;
}

function VideoPopup(props: VideoPopupProps) {
    const { open, selectedData, classDataLabel, categoryDataLabel, onClose } = props;
    const { date, video_file_name } = selectedData;
    const authentication = useSelector((state: RootStateInterface) => state.authentication);
    const token = authentication.authentication?.access_token;

    let videourl = `${API_BASE_URL}/record/videofile/${video_file_name}?token=${token}`;

    return (
        <div className="videoo">
            <Dialog open={open} onClose={onClose} className="video-dialog" classes={{ paper: "video-dialog-card" }} TransitionComponent={Slide} fullWidth={true} maxWidth="lg">
                <div className="dialog-container">
                    <Card className="dialog-content-card">
                        <CardContent className="card-content">
                            <div className="video-container">
                                <video
                                    src={videourl}
                                    controls={true}
                                    className="video"
                                />
                            </div>
                            <div className="video-details-container">
                                <div className="video-details-header">
                                    <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
                                        <Close />
                                    </IconButton>
                                </div>
                                <div className="video-details">
                                    <div className="video-details-item">
                                        <Typography variant="body1" className="video-details-item-title">Datum</Typography>
                                        <Typography variant="body1" className="video-details-item-value">{date}</Typography>
                                    </div>

                                    {selectedData.class.length > 0 && (
                                        <div className="video-details-item">
                                            <Typography variant="body1" className="video-details-item-title">Klassen</Typography>
                                            <Table className="value-table">
                                                {selectedData.class.map((classItem, index) => (
                                                    <TableRow key={index} className="table-row">
                                                        <TableCell className="table-cell">{classDataLabel.find(classLabel => classLabel.id === classItem.id)?.name}</TableCell>
                                                        <TableCell className="table-cell">{Math.round(classItem.probability)}%</TableCell>
                                                    </TableRow>
                                                ))}
                                            </Table>
                                        </div>
                                                )}

                                    {selectedData.category.length > 0 && (
                                        <div className="video-details-item">
                                            <Typography variant="body1" className="video-details-item-title">Kategorien</Typography>
                                            <Table className="value-table">

                                                {selectedData.category.map((categoryItem, index) => (
                                                    <TableRow key={index} className="table-row">
                                                        <TableCell className="table-cell">{categoryDataLabel.find(categoryLabel => categoryLabel.id === categoryItem.id)?.name}</TableCell>
                                                        <TableCell className="table-cell">{Math.round(categoryItem.probability)}%</TableCell>
                                                    </TableRow>
                                                ))}

                                            </Table>
                                        </div>
                                                )}

                                </div>
                            </div>
                        </CardContent>
                    </Card>
                </div>
            </Dialog>
        </div>
    )
}

export default VideoPopup;