import { useState } from 'react';
import Header from '../../ui/Header/Header';
import Sidebar from '../../ui/Sidebar/Sidebar';
import { Outlet } from 'react-router-dom';
import './DashboardLayout.css';

function DashboardLayout() {
  const [isDrawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setDrawerOpen(!isDrawerOpen);
  };

  return (
    <div className="dashboard-layout">
      <Sidebar isDrawerOpen={isDrawerOpen} />
      {!isDrawerOpen && <div className="overlay" onClick={toggleDrawer}></div>}
      <div className="section">
        <Header toggleDrawer={toggleDrawer} isDrawerOpen={isDrawerOpen} />
        <div className="content">
          <Outlet />
        </div>
      </div>
    </div >
  );
};

export default DashboardLayout;
