import React, { useState } from 'react';

interface EditContainerControllerInterface {
    item: any;
    onUpdate?: (item: any) => void;
}

function useEditContainerController(props: EditContainerControllerInterface) {
    const { item, onUpdate } = props;
    const [edit, setEdit] = useState<boolean>(false);
    const [editItem, setEditItem] = useState<any>(item);
    const [open, setOpen] = useState<boolean>(false);

    const openEditHandler = () => {
        setEdit(true);
    }

    const closeEditHandler = () => {
        setEditItem(item);
        setEdit(false);
    }

    const updateItemHandler = () => {
        if (onUpdate === undefined) return;

        onUpdate(editItem);
        closeEditHandler();
    }

    const openDialogHandler = () => {
        setOpen(true);
    }

    const closeDialogHandler = () => {
        setOpen(false);
    }

    const changeNestedItemHandler = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, key: string) => {
        setEditItem({ ...editItem, [key]: { ...editItem[key], id: event.target.value } })
    }

    const changeItemHandler = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, key: string) => {
        setEditItem({ ...editItem, [key]: event.target.value });
    }

    return {
        edit,
        editItem,
        open,
        openEditHandler,
        closeEditHandler,
        updateItemHandler,
        openDialogHandler,
        closeDialogHandler,
        changeNestedItemHandler,
        changeItemHandler
    }
}

export default useEditContainerController;