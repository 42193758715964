import { Avatar, Box } from '@mui/material';
import ABPALogo from '../../../assets/logos/ABPA_logo.jpg';
import './Splash.css';

function Splash() {
    return (
        <div className="splash">
            <Box boxShadow={24} className="avatar-box">
                <Avatar className="avatar">
                    <img src={ABPALogo} alt="ABPA-Logo" />
                </Avatar>
            </Box>
        </div>
    )
}

export default Splash;