import * as actionTypes from '../action-types/snackbar.action-types';
import { SnackbarStateInterface } from '../state-interfaces/snackbar.state-interface';

const initialState: SnackbarStateInterface = {
    errorMessage: '',
    successMessage: '',
    isOpen: false,
};

const snackbarReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case actionTypes.SET_SUCCESS_MESSAGE:
            return {
                ...state,
                successMessage: action.payload,
                isOpen: true,
            };
        case actionTypes.SET_ERROR_MESSAGE:
            return {
                ...state,
                errorMessage: action.payload,
                isOpen: true,
            };
        case actionTypes.RESET_MESSAGES:
            return {
                ...state,
                errorMessage: '',
                successMessage: '',
                isOpen: false,
            };
        default:
            return state;
    }
};

export default snackbarReducer;