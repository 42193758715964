import { RootResourceService } from '../root/root-resource-service';
import axios from 'axios';
import { MobileLogSummaryInterface, EditMobileLogSummaryInterface } from './mobile-log-summary.interface';

export class MobilLogService extends RootResourceService<MobileLogSummaryInterface> {
    constructor() {
        super('mobile-log');
    }

    async updateStatus(token: string, data: MobileLogSummaryInterface): Promise<any> {
        try {
            const editMobileConfiguration: EditMobileLogSummaryInterface = { ...data };

            const response = await axios.put<string>(`${this.resource_url}/${data.id}`, editMobileConfiguration, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            this.logger.log(`${this.resource_url} updated successfully.`);

            return response.data;
        } catch (error) {
            this.logger.error(error);

            throw error;
        }
    }
}