import { useState } from 'react';
import { ServerLogService } from '../service/server-log/server-log.service';
import { AuthenticationStateInterface } from '../store/state-interfaces/authentication.state-interface';

interface useServerLogHandlerInterface {
    authentication: AuthenticationStateInterface;
}

function useServerLogHandler(props: useServerLogHandlerInterface) {
    const [loading, setLoading] = useState(false);
    const [serverLogs, setServerLogs] = useState<string[]>([]);

    const serverLogService = new ServerLogService();

    const getServerLogs = async () => {
        if (!props.authentication.authentication?.access_token) return;

        setLoading(true);

        await serverLogService.getAllLogs(
            props.authentication.authentication.access_token,
            (serverLogs: string[]) => {
                setServerLogs(serverLogs);
            },
            (error: any) => {
                console.log(error);
            }
        );

        setLoading(false);
    };

    return {
        loading,
        serverLogs,
        getServerLogs
    };
}

export default useServerLogHandler;