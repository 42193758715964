import { Typography, Accordion, AccordionSummary, AccordionDetails, TextField, List, ListItem, Card, CardContent, FormLabel, Button, OutlinedInput } from '@mui/material';
import { ExpandMoreOutlined } from '@mui/icons-material';
import FullScreenDialog from '../../ui/FullScreenDialog/FullScreenDialog';
import DocumentTitleContainer from '../../container/DocumentTitleContainer/DocumentTitleContainer';
import useEditProfileController from '../../../hooks/useEditProfile.controller';
import './EditProfile.css';

interface EditProfilePropsInterface {
    openDialog: boolean,
    closeDialogHandler: () => void,
}

function EditProfile(props: EditProfilePropsInterface) {
    const { openDialog, closeDialogHandler } = props;
    const { handleNewPasswordChange, handleConfirmPasswordChange, changePassword, newPassword, confirmPassword, newPasswordError, username, role } = useEditProfileController();

    return (
        <DocumentTitleContainer title="Profil bearbeiten">
            <FullScreenDialog openDialog={openDialog} closeDialogHandler={closeDialogHandler} title="Einstellungen">
                <div className="profile-dialog-container">
                    <List className="profile-dialog-list">
                        <ListItem className="profile-dialog-list-item">
                            <div className="profile-dialog-header">
                                <Typography variant="h6" component="div" className="personal-information-title">
                                    Account Informationen
                                </Typography>
                            </div>
                            <Card className="profile-dialog-card">
                                <CardContent className="card-content">
                                    <div className="card-item">
                                        <div className="item-summary">
                                            <Typography className="summary-title" variant="body1">Nutzername</Typography>
                                            <Typography className="summary-data" variant="body1">{username}</Typography>
                                        </div>
                                    </div>
                                    <div className="card-item">
                                        <div className="item-summary">
                                            <Typography className="summary-title" variant="body1" component="div">Rolle</Typography>
                                            <Typography className="summary-data" variant="body1" component="div">{role}</Typography>
                                        </div>
                                    </div>
                                    <div className="card-item">
                                        <Accordion className="accordion" disableGutters elevation={0} classes={{ root: "accordion-root", expanded: "profile-dialog-accordion-expanded" }}>
                                            <AccordionSummary className="accordion-summary" expandIcon={<ExpandMoreOutlined className="expand-icon" />}>
                                                <Typography className="accordion-summary-title" variant="body1">Passwort ändern</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails className="accordion-details">
                                                <div className="form-container">
                                                    <div className="form-item">
                                                        <FormLabel className="input-label" component="legend">Neues Passwort eingeben</FormLabel>
                                                        <OutlinedInput className="input-field" type="password" fullWidth onChange={handleNewPasswordChange} value={newPassword} />
                                                    </div>
                                                    <div className="form-item">
                                                        <FormLabel className="input-label" component="legend">Passwort bestätigen</FormLabel>
                                                        <OutlinedInput className="input-field" type="password" fullWidth onChange={handleConfirmPasswordChange} value={confirmPassword} />
                                                    </div>
                                                    <div className="form-item">
                                                        <Typography className="error-message" variant="body2" color="error">{newPasswordError}</Typography>
                                                    </div>
                                                    <div className="form-item">
                                                        <Button className="form-button" variant="contained" color="primary" fullWidth onClick={changePassword}>Passwort ändern</Button>
                                                    </div>
                                                </div>
                                            </AccordionDetails>
                                        </Accordion>
                                    </div>
                                </CardContent>
                            </Card>
                        </ListItem>
                    </List>
                </div>
            </FullScreenDialog>
        </DocumentTitleContainer>
    )
}

export default EditProfile;