import { Dialog, AppBar, Toolbar, Box, IconButton, Typography, List, ListItem, TextField } from '@mui/material';
import { Close, Upload } from '@mui/icons-material';

interface CreateDataProps {
    openDialog: boolean;
    closeDialogHandler: () => void;
    data: any;
    setData: any;
    createData: () => void;
}

function CreateData(props: CreateDataProps) {
    const { openDialog, closeDialogHandler, data, setData, createData } = props;

    return (
        <Dialog open={openDialog} className="content-dialog" classes={{ paper: "content-dialog-card" }}>
            <AppBar className="content-dialog-appbar" position="relative" elevation={1}>
                <Toolbar className="dialog-toolbar">
                    <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
                        <Box display="flex" justifyContent="space-between" alignItems="center" gap={1}>
                            <IconButton
                                className="dialog-button"
                                onClick={closeDialogHandler}
                                aria-label="close"
                                edge="start"
                            >
                                <Close />
                            </IconButton>
                            <Typography variant="h6" component="div" className="dialog-title">
                                Neue Daten hinzufügen
                            </Typography>
                        </Box>
                        <IconButton
                            className="dialog-button"
                            autoFocus
                            onClick={createData}
                        >
                            <Upload />
                        </IconButton>
                    </Box>
                </Toolbar>
            </AppBar>
            <List className="content-dialog-list">

                {Object.keys(data).map((key: string, index: number) => (
                    <ListItem key={index} className="dialog-list-item">
                        <TextField
                            className="dialog-textfield"
                            variant="outlined"
                            fullWidth
                            type="text"
                            value={data[key]}
                            label={key}
                            onChange={(event) => setData({ ...data, [key]: event.target.value })}
                        />
                    </ListItem>
                ))}

            </List>
        </Dialog>
    )
}

export default CreateData;
