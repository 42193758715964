import { RootResourceService } from '../root/root-resource-service';
import { LocationResponsibilityInterface } from './location-responsibility.interface';
import axios from 'axios';

export class LocationResponsibilityService extends RootResourceService<LocationResponsibilityInterface> {
    constructor() {
        super('location-responsibility');
    }

    async getByUser(token: string): Promise<LocationResponsibilityInterface[]> {
        try {
            const response = await axios.get<LocationResponsibilityInterface[]>(`${this.resource_url}/user`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            this.logger.log(`${this.resource_url} fetched successfully.`);

            return response.data;
        } catch (error) {
            this.logger.error(error);

            throw error;
        }
    }
}
