import { useState } from 'react';
import { PASSWORD_REGEX } from '../common/constants';
import { useSelector, useDispatch } from 'react-redux';
import * as snackbarActions from '../store/actions/snackbar.actions';
import { RootStateInterface } from '../store/state-interfaces/root.state-interface';
import { UserService } from '../service/user/user.service';

function useEditProfileController() {
    const [newPassword, setNewPassword] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');
    const [newPasswordError, setNewPasswordError] = useState<string>('');
    const authentication = useSelector((state: RootStateInterface) => state.authentication);
    const dispatch = useDispatch();
    const { username, role } = authentication.authentication || {};
    const userService = new UserService();

    const handleNewPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNewPassword(event.target.value);
    }

    const handleConfirmPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setConfirmPassword(event.target.value);
    }

    const validateNewPassword = () => {
        setNewPasswordError('');
        if (newPassword.length > 0 && newPassword !== confirmPassword) {
            setNewPasswordError('Die Passwörter stimmen nicht überein.');
            return false;
        }
        else if (newPassword.length > 0 && !PASSWORD_REGEX.test(newPassword) && newPassword === confirmPassword) {
            setNewPasswordError('Das Passwort muss mindestens 8 Zeichen, mindestens ein Großbuchstabe, ein Kleinbuchstabe, eine Zahl und ein Sonderzeichen enthalten.');
            return false;
        } else {
            setNewPasswordError('');
            return true;
        }
    }

    const changePassword = async () => {
        if (validateNewPassword()) {
            if (!authentication.authentication?.access_token) return;

            try {
                await userService.changePassword(authentication.authentication.access_token, { password: newPassword });

                dispatch(snackbarActions.setSuccessMessage('Passwort erfolgreich geändert') as any);

                setNewPassword('');
                setConfirmPassword('');
            } catch (error) {
                dispatch(snackbarActions.setErrorMessage('Passwort konnte nicht geändert werden') as any);
            }
        }
    }

    return {
        newPassword,
        confirmPassword,
        newPasswordError,
        handleNewPasswordChange,
        handleConfirmPasswordChange,
        changePassword,
        username,
        role
    }
}

export default useEditProfileController;