import { Avatar, Typography } from '@mui/material';
import ABPALogo from '../../../assets/logos/ABPA_logo.jpg';
import './Title.css';

function Title() {
    return (
        <div className="title">
            <Avatar className="avatar">
                <img src={ABPALogo} alt="ABPA-Logo" />
            </Avatar>
            <Typography variant="h3" align="left" fontWeight={600} color="white" letterSpacing={8}>
                ABPA
            </Typography>
        </div>
    )
}

export default Title;