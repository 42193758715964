import { useState } from 'react';
import { GeoresultService } from '../service/georesult/georesult.service';
import { RootStateInterface } from '../store/state-interfaces/root.state-interface';
import { useSelector, useDispatch } from 'react-redux';
import * as snackbarActions from '../store/actions/snackbar.actions';
import { PropertyTypeInterface, SimplifiedGeoresultCollectionInterface } from '../service/georesult/simplified-georesult.interface';

function useAnalysationDataController() {
    const authentication = useSelector((state: RootStateInterface) => state.authentication);
    const dispatch = useDispatch();
    const georesultService = new GeoresultService();
    const [data, setData] = useState<SimplifiedGeoresultCollectionInterface[] | null>(null);
    const [selectedData, setSelectedData] = useState<SimplifiedGeoresultCollectionInterface | null>(null);
    const [classDataLabel, setClassDataLabel] = useState<PropertyTypeInterface[] | null>(null);
    const [categoryDataLabel, setCategoryDataLabel] = useState<PropertyTypeInterface[] | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const getSimplifiedGeoresults = async () => {
        if (!authentication.authentication?.access_token) return;

        setLoading(true);
        try {
            const response = await georesultService.getSimplifiedGeoresults(authentication.authentication.access_token);

            setData(response.georesults);
            setClassDataLabel(response.classes);
            setCategoryDataLabel(response.categories);

            dispatch(snackbarActions.setSuccessMessage(`Georesults erfolgreich geladen`) as any);
        } catch (exception) {
            setError('Fehler beim Laden der Daten');

            dispatch(snackbarActions.setErrorMessage('Fehler beim Laden der Daten') as any);
        }

        setLoading(false);
    }

    const selectData = (data: SimplifiedGeoresultCollectionInterface) => {
        setSelectedData(data);
    }

    const unselectData = () => {
        setSelectedData(null);
    }

    const downloadData = () => {
        if (!authentication.authentication?.access_token) return;

        if (data) {
            const data2 = JSON.stringify(data);

            const element = document.createElement("a");
            const file = new Blob([data2], { type: 'text/plain' });
            element.href = URL.createObjectURL(file);
            element.download = `results.json`;
            document.body.appendChild(element);
            element.click();
        } else {
            dispatch(snackbarActions.setErrorMessage('Fehler beim Runterladen der Daten') as any);
        }
    }

    const downloadRawData = async () => {
        if (!authentication.authentication?.access_token) return;

        try {
            const response = await georesultService.getGeoresultsFile(authentication.authentication.access_token);

            const type = response.headers['content-type']
            const blob = new Blob([response.data], { type: type })
            const link = document.createElement('a')

            link.href = window.URL.createObjectURL(blob)
            link.download = 'results_raw.geojson'
            link.click()
        } catch (error) {
            setError('Fehler beim Runterladen der Daten');

            dispatch(snackbarActions.setErrorMessage('Fehler beim Runterladen der Daten') as any);
        }
    }

    return {
        data,
        classDataLabel,
        categoryDataLabel,
        loading,
        error,
        getSimplifiedGeoresults,
        selectData,
        unselectData,
        selectedData,
        downloadData,
        downloadRawData
    }
}

export default useAnalysationDataController;