import { MobileUserService } from '../../../../service/mobile-user/mobile-user.service';
import DataContainer from '../../../container/DataContainer/DataContainer';

function MobileUsers() {
    return (
        <DataContainer
            resourceService={new MobileUserService()}
            title="Mobile Users"
            deleteAllowed={true}
            updateAllowed={false}
        />
    )
}

export default MobileUsers;