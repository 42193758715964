import { RootResourceService } from '../root/root-resource-service';
import { EditProblempointInterface, ProblempointInterface } from './problempoint.interface';
import axios from 'axios';

export class ProblempointService extends RootResourceService<ProblempointInterface> {
    constructor() {
        super('problempoint/type');
    }

    async update(token: string, data: ProblempointInterface): Promise<any> {
        try {
            const editProblempoint: EditProblempointInterface = { ...data };

            const response = await axios.put<string>(`${this.resource_url}/${data.id}`, editProblempoint, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            this.logger.log(`${this.resource_url} updated successfully.`);

            return response.data;
        } catch (error) {
            this.logger.error(error);

            throw error;
        }
    }
}
