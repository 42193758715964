import React, { useState, useEffect, useRef } from 'react';
import DocumentTitleContainer from '../../container/DocumentTitleContainer/DocumentTitleContainer';
import { Card, CardContent, CardHeader, Typography, Box, List, ListItem } from '@mui/material';
import { RecordService } from '../../../service/record/record.service';
import { RecordInterface } from '../../../service/record/record.interface';
import './RecordData.css';
import { ACCESS_TOKEN_NAME } from '../../../common/constants';
import { ApplicationName } from '../../../service/mobile-user/application-name.enum';
import RoutingMapContainer from '../../container/RoutingMapContainer/RoutingMapContainer';
import { Polyline } from 'react-leaflet';
import RoutingLine from './RoutingLine';

function RecordData() {
    const [recordData, setRecordData] = React.useState<RecordInterface[]>([]);
    const recordService = new RecordService();

    useEffect(() => {
        (async () => {
            await getAllRecords();
        })();
    }, []);

    const getAllRecords = async () => {
        const token: string | null = localStorage.getItem(ACCESS_TOKEN_NAME);

        if (!token) return;

        const data = await recordService.getByApplication(token, ApplicationName.ABPA_MOBILE);
        setRecordData(data);
    }

    return (
        <DocumentTitleContainer title="BaAN-Frei Aufnahmen">
            <div className="data-container analysation-data">
                <Card className="data-card">
                    <CardContent className="card-content">
                        <CardHeader className="card-header" title={
                            <Box className="header-box" display="flex" justifyContent="space-between" alignItems="center">
                                <div className="header-box-left">
                                    <Typography variant="h4" align="left" gutterBottom fontWeight={700} color="primary" className="content-title">
                                        BaAN-Frei Aufnahmen
                                    </Typography>
                                </div>
                            </Box>
                        } />
                        <Box className="content-box" display="flex" justifyContent="center" alignItems="center" gap={4}>
                            <div className="content-container">
                                <RoutingMapContainer>
                                    {recordData.length > 0 && recordData.map((record, index) => (
                                        <RoutingLine key={index} recordData={record} />
                                    ))}
                                </RoutingMapContainer>
                            </div>
                        </Box>
                        {recordData.length > 0 && recordData.map((record, index) => (
                            <List key={index} className="record-list">
                                <ListItem>
                                    <Typography variant="h6" align="left" gutterBottom fontWeight={700} color="primary">
                                        {record.mobileUser.name}
                                    </Typography>
                                </ListItem>
                                <ListItem>
                                    <Typography variant="body1" align="left" gutterBottom>
                                        {record.biketype.name}
                                    </Typography>
                                </ListItem>
                                <ListItem>
                                    <Typography variant="body1" align="left" gutterBottom>
                                        {record.recordLocation[0].latitude}, {record.recordLocation[0].longitude}
                                    </Typography>
                                </ListItem>
                                <ListItem>
                                    <Typography variant="body1" align="left" gutterBottom>
                                        {record.recordLocation[record.recordLocation.length - 1].latitude}, {record.recordLocation[record.recordLocation.length - 1].longitude}
                                    </Typography>
                                </ListItem>
                            </List>
                        ))}
                    </CardContent>
                </Card>
            </div>
        </DocumentTitleContainer>
    )
}

export default RecordData