import { RootResourceInterface } from './root-resource.interface';
import { Logger } from '../../utils/logger';
import axios from 'axios';
import { API_BASE_URL } from '../../common/constants';

export class RootResourceService<T> {
    constructor(resource_url: string) {
        this.resource_url = `${API_BASE_URL}/${resource_url}`;
        this.logger = new Logger(this.constructor.name);
    }

    protected resource_url: string;
    protected logger: Logger;

    getResouceUrl(): string {
        return this.resource_url;
    }

    async getAll(token: string): Promise<T[]> {
        try {
            const response = await axios.get<T[]>(`${this.resource_url}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            console.log(response.data);
            this.logger.log(`${this.resource_url} fetched successfully.`);

            return response.data;
        } catch (error) {
            this.logger.error(error);

            throw error;
        }
    }

    async getById(token: string, id: number): Promise<T> {
        try {
            const response = await axios.get<T>(`${this.resource_url}/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            this.logger.log(`${this.resource_url} with id ${id} fetched successfully.`);

            return response.data;
        } catch (error) {
            this.logger.error(error);

            throw error;
        }
    }

    async create<D = T>(token: string, data: T | D): Promise<string | T> {
        try {
            const response = await axios.post<string | T>(`${this.resource_url}`, data, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            this.logger.log(`${this.resource_url} created successfully.`);

            return response.data;
        } catch (error) {
            this.logger.error(error);

            throw error;
        }
    }

    async update(token: string, data: RootResourceInterface): Promise<string> {
        try {
            const response = await axios.put<string>(`${this.resource_url}/${data.id}`, data, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            this.logger.log(`${this.resource_url} updated successfully.`);

            return response.data;
        } catch (error) {
            this.logger.error(error);

            throw error;
        }
    }

    async delete(token: string, id: number): Promise<string> {
        try {
            const response = await axios.delete<string>(`${this.resource_url}/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            this.logger.log(`${this.resource_url} deleted successfully.`);

            return response.data;
        } catch (error) {
            this.logger.error(error);

            throw error;
        }
    }
}